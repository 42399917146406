import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import NavLinkApp from "../common/NavLinkApp";

@inject("AppStore")
@observer
class PageShipping extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    let target = "_blank";
    return (
      <React.Fragment>
        <Container className={"w768"}>
          <div className="text">
            <h1>Пользовательское соглашение</h1>
            <p>
              Настоящее пользовательское соглашение (далее – «Оферта»), в
              соответствии с положениями ст. 437 ГК РФ является публичной
              офертой Акционерного общества «Самокарт», (далее – «Чекбокс»), и
              адресовано любому дееспособному лицу. Безусловным акцептом
              Клиентом настоящей Оферты является оформление доставки на Сайте
              checkbox.ru.
            </p>
            <h2>1. ОСНОВНЫЕ ТЕРМИНЫ</h2>
            <p>
              1.1. Оферта – настоящее соглашение, заключаемое между Чекбоксом и
              Клиентами на условиях, указанных ниже.
            </p>
            <p>
              1.2. Клиент – пользователь Сайта, полностью дееспособное
              физическое лицо, которое прошло процедуру регистрации на Сайте,
              путем присвоения логина и пароля, и разместившее заявку на
              доставку разрешенных к перевозке предметов.
            </p>
            <p>
              1.3. Чекбокс – оператор, владелец и администратор Сайта
              checkbox.ru, Акционерное общество «Самокарт» (ИНН 2310212217,
              юридический адрес: Россия, г. Москва, вн. тер. г. муниципальный
              округ Хорошево-Мневники, пр-кт Маршала Жукова, д. 4, стр. 1,
              помещ. 9),организующее доставку Заказов по Заявкам, размещенным
              Клиентами на Сайте.
            </p>
            <p>
              1.4. Курьер – лицо, оказывающее услуги по доставке с помощью
              Интерфейса Чекбокс.
            </p>
            <p>
              1.5. Сайт – web checkbox.ru, который принадлежит и
              администрируется Чекбоксом.
            </p>
            <p>
              1.6. Интерфейс – информационная система, web или мобильное
              приложение Сheckbox, позволяющее Клиенту знакомиться с тарифами
              доставки, заполнить все данные Заявки, необходимые для ее
              осуществления (адрес отправления и получения, время доставки, и
              т.д.), а также совокупность информации о состоянии и движении
              размещенных Клиентом Заявок, и иной информации и средств ее
              обработки, используемой сторонами для обмена информацией в
              электронном виде.
            </p>
            <p>
              1.7. Заявка - оформленный в личном кабинете электронный запрос на
              предоставление услуг по доставке разрешенных к перевозке в РФ
              предметов для личного пользования, содержит в себе информацию о
              Заказе, адресах доставки в пределах Зоны доставки, передается
              через Интерфейс.
            </p>
            <p>
              1.8. Заказ, Отправление - указанный в заявке предмет или товар
              (разрешенный к перевозке на территории РФ), в отношении которого
              Клиент дает через Интерфейс поручение доставить по указанному
              Клиентом адресу.
            </p>
            <p>
              1.9. Адрес отправления – любой адрес в пределах Зоны доставки, по
              которому Курьер производит прием отправлений у лица, названного
              Клиентом отправителем, для последующей доставки по адресу
              доставки.
            </p>
            <p>
              1.10. Адрес доставки - любой адрес в пределах Зоны доставки, по
              которому Курьер производит вручение отправлений лицам, названным
              Клиентом получателем отправления.
            </p>
            <p>
              1.11. Зона доставки -{" "}
              <NavLinkApp to="/delivery-area">
                https://checkbox.ru/delivery-area
              </NavLinkApp>{" "}
              . Чтобы увидеть зону доставки по необходимому городу, нужно
              выбрать его в меню сайта.
            </p>
            <p>
              1.12. Простая электронная подпись (ПЭП) - электронная подпись
              (информация в электронном виде), которая посредством использования
              кодов подтверждения, паролей, sms или иных средств подтверждает
              факт формирования электронной подписи (отправления сообщения)
              определенным лицом (Клиентом, Чекбоксом, Курьером).
            </p>
            <p>
              1.13. Уведомление – электронное сообщение, направляемое Сторонами
              через Интерфейс, а также при возможности по электронной почте.
            </p>
            <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
            <p>
              2.1. В соответствии со ст. 437 Гражданского кодекса Российской
              Федерации данный документ, адресованный физическим лицам, является
              безотзывным предложением АО «Самокарт» заключить договор публичной
              Оферты на указанных ниже условиях (далее — Оферта).
            </p>
            <p>
              2.2. Полным и безоговорочным акцептом настоящей Оферты является
              принятие Клиентом условий настоящей Оферты при нажатии кнопки
              «Оформить заявку» или «Оплатить».
            </p>
            <p>
              2.3. Акцепт Оферты означает, что Клиент согласен со всеми
              положениями и равносилен заключению договора услуг по доставке,
              что Клиент производит оплату и принимает услуги по доставке в
              соответствии с настоящей Офертой, а также дает свое разрешение на
              обработку своих персональных данных для получения услуг в
              соответствии с размещенной Заявкой.
            </p>
            <p>
              2.4. Если Клиент не согласен с каким-либо пунктом настоящей
              Оферты, он не должен размещать и оплачивать Заявки на Сайте
              Checkbox.
            </p>
            <h2>3. ПРЕДМЕТ</h2>
            <p>
              3.1. Чекбокс обязуется по заявке, размещенной Клиентом через Сайт
              оказать услуги по доставке Отправления, разрешенного к перевозке
              законодательством РФ, по указанному Клиентом адресу в пределах
              зоны доставки по адресу доставки, а Клиент обязуется оплатить эти
              услуги в соответствии с условиями настоящей Оферты.
            </p>
            <p>
              3.2. Моментом исполнения обязательств Чекбокса является отметка
              Курьера Чекбокса в специальном приложении для мобильного телефона
              Checkbox (далее – «Интерфейс») при передаче отправления Курьеру, о
              чем Клиенту приходит уведомление в виде текстового (SMS) сообщения
              на указанный им номер мобильного телефона.
            </p>
            <p>
              3.3. Моментом исполнения обязательств Курьера по доставке Заказа
              является отметка Курьера Чекбокса в Интерфейсе при передаче
              отправления получателю, по адресу получения, о чем Клиенту
              приходит уведомление в виде текстового (SMS) сообщения на
              указанный им номер мобильного телефона.
            </p>
            <h3>4. ОФОРМЛЕНИЕ ЗАЯВКИ НА ДОСТАВКУ</h3>
            <p>
              {" "}
              4.1. Для оформления Заявки на услуги по доставке Клиенту
              необходимо указать на Сайте телефон, и подтвердить его паролем,
              который придет на указанный Клиентом номер.
            </p>
            <p>
              {" "}
              4.2. Для оформления Заявки на услуги по доставке Клиенту
              необходимо указать следующую информацию:
            </p>
            <p> 4.2.1. Адрес отправления</p>
            <p> 4.2.2. Телефон лица, передающего Заказ Курьеру</p>
            <p> 4.2.3. Адрес доставки</p>
            <p> 4.2.4. Телефон получателя Заказа</p>
            <p> 4.2.5. Пояснения, при необходимости</p>
            <p>
              {" "}
              4.3. При подтверждении Заявки на сайте Клиент должен ознакомиться
              с настоящей Офертой и принять условия данной Оферты путем
              простановки отметки «Согласен».
            </p>
            <p>
              {" "}
              4.4. После этого Клиент будет перенаправлен на сайт банка-эквайера
              (АО «АЛЬФА-БАНК») для оплаты доставки (раздел 6 Оферты).
            </p>
            <p>
              {" "}
              4.5. Чекбокс не несет ответственности за точность и правильность
              информации, предоставляемой Клиентом при оформлении Заявки, а
              также за содержание Отправления.
            </p>
            <p>
              {" "}
              4.6. Клиент, присоединяясь к настоящей Оферте, соглашается на
              электронный документооборот и электронное взаимодействие. Номер
              мобильного телефона, указанного при регистрации, признаются
              электронной информацией, приравненной к простой электронной
              подписи.
            </p>
            <h2>5. ПОРЯДОК ДОСТАВКИ, ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
            <p>5.1. Чекбокс:</p>
            <p>
              {" "}
              5.1.1. Чекбокс обязуется по Заявке, размещенной Клиентом через
              Сайт, оказать услуги по доставке Отправления, разрешенного к
              перевозке законодательством РФ, по указанному Клиентом адресу в
              пределах зоны доставки по адресу доставки.
            </p>
            <p> 5.2. Курьер:</p>
            <p>
              {" "}
              5.2.1. Обязуется забрать Отправление у отправителя и доставить
              Отправление получателю в соответствии с информацией, указанной в
              Заявке.
            </p>
            <p>
              {" "}
              5.2.2. В случае невозможности забрать Отправление в связи с
              отсутствием отправителя в месте и время, указанное в Заявке, либо
              по любой другой не зависящей от Чекбокса и Курьера причине, Курьер
              обязуется уведомить Клиента путем направления текстового сообщения
              на указанный Клиентом номер мобильного телефона.
            </p>
            <p>
              {" "}
              5.2.3.В случае если доставку Отправления невозможно выполнить по
              причине отсутствия получателя в месте и время, указанное в Заявке,
              либо по любой другой не зависящей от Чекбокса и Курьера причине,
              КУрьер обязуется уведомить об этом Клиента и вернуть обратно
              отправителю Отправление, при этом доставка Отправления получателю
              считается выполненной и подлежит оплате.
            </p>
            <p>
              {" "}
              5.2.4. Курьер вправе осмотреть Отправление и отказать Клиенту в
              оказании услуги доставки Отправления, если оно содержит:{" "}
            </p>
            <ul>
              <li>иностранную валюту и денежные знаки РФ;</li>
              <li> живые или мертвые животные и растения;</li>
              <li>
                {" "}
                огнестрельное, пневматическое, газовое или холодное оружие и его
                части, боеприпасы, фейерверки, сигнальные ракеты и патроны;
              </li>
              <li>
                {" "}
                наркотические средства, психотропные, сильнодействующие,
                токсичные, радиоактивные, взрывчатые, ядовитые, едкие,
                легковоспламеняющиеся и другие опасные вещества, в том числе
                находящиеся под давлением;
              </li>
              <li> медицинские препараты;</li>
              <li>
                {" "}
                продукты с неприятным запахом, предметы и вещества, которые по
                своему характеру или из-за упаковки могут представлять опасность
                для людей, загрязнять или портить (повреждать) другие грузы,
                окружающих людей или предметы, изделия из золота, серебра,
                других драгоценных и полудрагоценных металлов и камней
              </li>
              <li> любые отправления, превышающие параметры 100х70х50 см;</li>
              <li> любые отправления за пределы Зоны доставки.</li>
              <li>
                {" "}
                любые отправления, нарушающие{" "}
                <a href="https://seller.checkbox.ru/order" target={target}>
                  Требования
                </a>{" "}
                к упаковке и передаче товара.
              </li>
            </ul>
            <p>
              5.2.5. Курьер вправе отказаться от доставки Отправления, если оно
              не соответствует описанию в Заявке или если фактические условия
              оказания услуг по доставке, о которых Курьеру сообщил отправитель
              либо Клиент (в зависимости от ситуации), не соответствуют другим
              условиям Заявки.
            </p>
            <p>
              {" "}
              5.3. Доставка осуществляется в пределах Зоны доставки с 09:00 до
              23:00. Клиент соглашается с тем, что время доставки может меняться
              по независящим от Чекбокса и Курьера причинам - поломка
              автомобиля, ДТП, пробки на дорогах и т.п. В этом случае Клиент и
              Чекбокс согласовывают новое время доставки.
            </p>
            <p> 5.4. Клиент:</p>
            <p>
              {" "}
              5.4.1. Обязуется передать Курьеру Отправление в месте и в сроки,
              указанные в Заявке;
            </p>
            <p>
              {" "}
              5.4.2. Обязуется не передавать Отправления для доставки Курьеру
              указанные в п. 5.2.4. настоящей Оферты предметы;
            </p>
            <p>
              {" "}
              5.4.3. Обязуется самостоятельно обеспечить Отправление особой
              упаковкой, соответствующей характеру вложения, условиям и
              продолжительности пересылки, исключающей возможность повреждения
              вложения, а также причинения какого-либо вреда другим
              отправлениям;
            </p>
            <p>
              {" "}
              5.4.4. Обязуется незамедлительно известить Чекбокс и Курьера через
              Интерфейс о любых изменениях в Заявке. Изменения могут быть
              сделаны до момента доставки Отправления.
            </p>
            <p>
              {" "}
              5.4.5.Обязуется производить все расчеты с Курьером через Интерфейс
              Checkbox в полном объеме и своевременно.
            </p>
            <p>
              {" "}
              5.4.6. Вправе отозвать Заявку до момента принятия Заявки. В случае
              отзыва Заявки после ее принятия Чекбоксом, но до момента передачи
              Отправления Чекбоксу, Клиент оплачивает выезд Курьера по цене
              доставки.
            </p>
            <h2>6. ОПЛАТА УСЛУГ ПО ДОСТАВКЕ</h2>
            <p>
              6.1. Стоимость услуги по доставке Отправления по адресу получения
              будет сформирована в Интерфейсе Чекбокс при заполнении всех данных
              Заявки, необходимых для ее осуществления (адрес отправления и
              получения, время доставки, и т.д.).
            </p>
            <p>
              {" "}
              6.2. Услуга оплачивается Клиентом с использованием электронных
              денежных средств, через платежную систему АО «АЛЬФА-БАНК». К
              оплате принимаются карты VISA, MasterCard, МИР.
            </p>
            <p>
              {" "}
              6.3. Денежные средства списываются со счета Клиента в момент
              размещения заявки, до выезда Курьера по адресу, указанному
              Клиентом, в качестве адреса отправления.
            </p>
            <p>
              {" "}
              6.4. Подтверждением оплаты по пластиковой карте на сайте будет
              являться электронный чек (слип), выдаваемый Клиентом
              банком-эквайером (АО «АЛЬФА-БАНК»). Электронный кассовый чек
              направляется Клиенту по электронной почте либо смс на телефонный
              номер, в зависимости от выбора Клиента при нажатии кнопки
              "Оплатить".
            </p>
            <h2>7. БЕЗОПАСНОСТЬ ПЛАТЕЖЕЙ, КОНФИДЕНЦИАЛЬНОСТЬ ИНФОРМАЦИИ</h2>
            <p>
              {" "}
              7.1. Услуга оплаты через интернет осуществляется в соответствии с
              Правилами международных платежных систем Visa, MasterCard и
              Платежной системы МИР на принципах соблюдения конфиденциальности и
              безопасности совершения платежа, для чего используются самые
              современные методы проверки, шифрования и передачи данных по
              закрытым каналам связи. Ввод данных банковской карты
              осуществляется на защищенной платежной странице АО «АЛЬФА-БАНК».
            </p>{" "}
            <p>
              {" "}
              7.2. На странице для ввода данных банковской карты потребуется
              ввести данные банковской карты: номер карты, имя владельца карты,
              срок действия карты, трёхзначный код безопасности (CVV2 для VISA,
              CVC2 для MasterCard, Код Дополнительной Идентификации для МИР).
              Все необходимые данные пропечатаны на самой карте. Трёхзначный код
              безопасности — это три цифры, находящиеся на обратной стороне
              карты.
            </p>{" "}
            <p>
              {" "}
              7.3. Далее вы будете перенаправлены на страницу Вашего банка для
              ввода кода безопасности, который придет к Вам в СМС. Если код
              безопасности к Вам не пришел, то следует обратиться в банк
              выдавший Вам карту.
            </p>{" "}
            <p> 7.4. Случаи отказа в совершении платежа:</p>{" "}
            <p>
              {" "}
              7.4.1. банковская карта не предназначена для совершения платежей
              через интернет, о чем можно узнать, обратившись в Ваш Банк;
            </p>{" "}
            <p>
              {" "}
              7.4.2. недостаточно средств для оплаты на банковской карте.
              Подробнее о наличии средств на банковской карте Вы можете узнать,
              обратившись в банк, выпустивший банковскую карту;
            </p>{" "}
            <p> 7.4.3. данные банковской карты введены неверно;</p>{" "}
            <p>
              {" "}
              7.4.4. истек срок действия банковской карты. Срок действия карты,
              как правило, указан на лицевой стороне карты (это месяц и год, до
              которого действительна карта). Подробнее о сроке действия карты Вы
              можете узнать, обратившись в банк, выпустивший банковскую карту;
            </p>{" "}
            <p>
              {" "}
              7.5. По вопросам оплаты с помощью банковской карты и иным
              вопросам, связанным с работой Сайта, Вы можете обращаться по
              телефону: 8 800 555-81-52.
            </p>{" "}
            <p>
              {" "}
              7.6. Предоставляемая вами персональная информация (имя, телефон,
              e-mail, номер банковской карты) является конфиденциальной и не
              подлежит разглашению. Данные вашей кредитной карты передаются
              только в зашифрованном виде, не обрабатываются Чекбоксом и не
              сохраняются на нашем Web-сервере.
            </p>
            <h2>8. ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ ЗА ДОСТАВКУ</h2>
            <p>
              8.1. Так как Чекбокс не действует от лица интернет-магазинов, не
              осуществляет выкуп Отправления, и не принимает участия в расчетах
              между получателями и отправителями, Чекбокс также не участвует в
              процедуре возврата денежных средств за товар.
            </p>{" "}
            <p>
              {" "}
              8.2. Клиент понимает и соглашается с тем, что осуществление
              доставки — это отдельная услуга, не являющаяся неотъемлемой частью
              стоимости доставляемого Чекбоксом Отправления, выполнение которой
              заканчивается в момент получения получателем Отправления. В связи
              с этим доставка Отправления не дает Клиенту права требования
              доставки предметов, входящих в Отправление, в целях гарантийного
              или иного обслуживания или замены, не дает возможности
              осуществлять гарантийное обслуживание или замену Отправления
              посредством выезда к Клиенту и не подразумевает возможность
              возврата стоимости доставки Отправления в случаях, когда Клиент
              имеет право на возврат денег за Отправление, в соответствии с
              Законом РФ «О защите прав потребителей».
            </p>{" "}
            <p>
              {" "}
              8.3. В случае, если Клиент желает воспользоваться услугами
              Чекбокса по доставке Отправления в целях, указанных в п.8.2., он
              оформляет через Сайт новую услугу по доставке.
            </p>{" "}
            <p>
              {" "}
              8.4. В случае, если Клиент ошибочно перечислил денежные средства
              через Интерфейс Чекбокса, либо желает отменить Заявку (что
              возможно до того, как Отправление было получено Курьером), Клиент
              может вернуть ошибочно перечисленные денежные средства.
            </p>{" "}
            <p>
              {" "}
              8.4.1. Для возврата денежных средств на банковскую карту
              необходимо заполнить «Заявление о возврате денежных средств»,
              которое высылается по требованию Чекбоксом на электронный адрес и
              оправить его вместе с приложением копии паспорта по адресу
              info@samocart.ru
            </p>{" "}
            <p>
              {" "}
              8.4.2. Возврат денежных средств будет осуществлен на банковскую
              карту в течение 21 (двадцати одного) рабочего дня со дня получения
              «Заявление о возврате денежных средств» Чекбоксом.
            </p>{" "}
            <p>
              {" "}
              8.4.3. Для возврата денежных средств по операциям проведенными с
              ошибками необходимо обратиться с письменным заявлением и
              приложением копии паспорта и чеков/квитанций, подтверждающих
              ошибочное списание. Данное заявление необходимо направить по
              адресу info@samocart.ru
            </p>{" "}
            <p>
              {" "}
              8.4.4. Сумма возврата будет равняться сумме перечисления. Срок
              рассмотрения Заявления и возврата денежных средств начинает
              исчисляться с момента получения Чекбоксом Заявления и
              рассчитывается в рабочих днях без учета праздников/выходных дней.
            </p>
            <h2>9. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
            <p>
              9.1. В случае неисполнения или ненадлежащего исполнения одной из
              сторон своих обязательств, Стороны несут ответственность согласно
              Оферте, споры разрешаются ими путем переговоров, а в части, не
              урегулированной Офертой – в соответствии с законодательством РФ.
              При недостижении Сторонами соглашения, споры и разногласия
              подлежат разрешению в претензионном порядке. Срок ответа на
              претензию – 30 дней.
            </p>{" "}
            <p>
              {" "}
              9.2. Клиент несет ответственность за достоверность сведений в
              Заявке. Чекбокс имеет право отказать в оказании услуги Клиенту при
              обнаружении недостоверности сведений, указанных в Заявке.
            </p>{" "}
            <p>
              {" "}
              9.3. Клиент несет полную ответственность за потенциально опасное
              Отправление и за вред, который может быть причинен в связи с
              доставкой такого Отправления.
            </p>{" "}
            <p>
              {" "}
              9.4. Чекбокс не несет ответственность перед получателем за
              возможное несоответствие Отправления его описанию, которое было
              предоставлено получателю Клиентом.
            </p>{" "}
            <p>
              {" "}
              9.5. Чекбокс несет ответственность перед Клиентом за сохранность
              Отправления только в размере объявленной стоимости Отправления.
              Клиент соглашается, что возмещению подлежит только прямой ущерб,
              при наличии вины Курьера или Чекбокса, моральный вред, косвенные
              убытки и упущенная выгода возмещению не подлежат.
            </p>{" "}
            <p>
              {" "}
              9.6. Потеря, повреждение Отправления (части Отправления) либо
              ненадлежащее оказание услуг фиксируются Чекбоксом и/или Клиентом и
              получателем при получении Отправления либо в момент, когда
              Отправление должно было быть получено. В этом случае составляется
              Акт об утере (повреждении) Отправления в произвольной форме, но с
              обязательным указанием основных условий Заявки и конкретных
              претензий.
            </p>{" "}
            <p> 9.7. Стороны согласовали, что:</p>{" "}
            <p>
              {" "}
              9.7.1. Любые требования к Чекбокусу или в связи с ним
              ограничиваются возмещением реального ущерба и ни при каких
              обстоятельствах не включают упущенную выгоду или иные косвенные
              убытки;
            </p>{" "}
            <p>
              {" "}
              9.7.2. Курьер и Чекбокс не несут ответственности за неисполнение
              или ненадлежащее исполнение своих обязательств, если нарушение
              сроков доставки произошли вследствие обстоятельств непреодолимой
              силы или свойств вложения Заказов, о которых Курьеру или Чекбоксу
              не было известно.
            </p>{" "}
            <p>
              {" "}
              9.7.3. Чекбокс не несет ответственности за невозможность
              использования Сайта либо Интерфейса по причинам, не зависящим от
              Чекбокса, любые действия или бездействия сервисов, сетей,
              программного обеспечения, оборудования, и т.д., а также за ущерб,
              который может быть нанесен любым устройствам и носителям
              информации Клиента в результате использования Интерфейса.
            </p>{" "}
            <p>
              {" "}
              9.7.4. Чекбокс полностью отвечает за соответствие своей
              контрольно-кассовой машины, выбивающей кассовый чек для Клиента,
              требованиям законодательства Российской Федерации.
            </p>{" "}
            <p>
              {" "}
              9.8. Неустойки и компенсация убытков, предусмотренные настоящей
              Офертой, начисляются и выплачиваются только на основании
              письменной претензии пострадавшей Стороны.
            </p>{" "}
            <p>
              {" "}
              9.9. Стороны освобождаются от ответственности за частичное или
              полное неисполнение обязательств по настоящей Оферте, если это
              неисполнение явилось следствием обстоятельств непреодолимой силы
              (форс-мажор), возникших после акцепта настоящей Оферты, в
              результате событий чрезвычайного характера, которые Клиент либо
              Чекбокс не могли ни предвидеть, ни предотвратить разумными мерами.
            </p>
            <h2>10. ПЕРСОНАЛЬНЫЕ ДАННЫЕ</h2>
            <p>
              10.1. Принимая настоящую Оферту, Клиент предоставляет Чекбоксу
              свое согласие на обработку своих персональных данных в объеме,
              установленном настоящей Офертой и их использование в целях
              оказания услуг. Данные, передаваемые Чекбоксу и Курьеру: Имя;
              Номер мобильного телефона.
            </p>{" "}
            <p>
              {" "}
              Целью обработки предоставленных персональных данных является
              визуальное отображение Чекбоксом, вышеперечисленных данных в
              смс-сообщениях, направляемых Клиентам с целью оповещения их о
              времени и параметрах доставки Заказа, совершенного на Сайте
              checkbox.ru и возможной связи Клиента с Курьером с целью уточнения
              состояния доставки.
            </p>{" "}
            <p>
              {" "}
              10.2. Обработка персональных данных осуществляется как с
              использованием средств автоматизации, так и без использования
              таких средств.
            </p>{" "}
            <p>
              {" "}
              10.3. В рамках Оферты обрабатываются персональные данные, не
              являющиеся биометрическими: сведения о местоположении, тип и
              версия ОС, тип устройства, информация о разделах мобильного
              приложения "Checkbox", посещаемых Клиентом, ip-адрес).
            </p>{" "}
            <p>
              {" "}
              10.4. Срок, в течение которого происходит обработка персональных
              данных: до момента отзыва согласия на обработку данных. Клиент
              может направить письменное заявление Чекбоксу по реквизитам,
              указанным в Оферте, с отзывом согласия на обработку данных. В
              случае отзыва Клиентом или его представителем согласия на
              обработку персональных данных, Чекбокс удаляет персональные данные
              в течение 30 (тридцати) дней.
            </p>{" "}
            <p>
              {" "}
              10.5. Чекбокс не обрабатывают персональные данные Клиентов, в
              смысле Федерального закона «О персональных данных» от 27.07.20106
              №152-ФЗ и иных нормативных актов Российской Федерации.
            </p>{" "}
            <p>
              {" "}
              10.6. Полные правила обработки данных урегулированы Политикой
              конфиденциальности Checkbox.
            </p>
            <h2>11. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h2>
            <p>
              11.1. Чекбокс является правообладателем Сайта, Интерфейса и их
              составных частей.
            </p>{" "}
            <p>
              {" "}
              11.2. Контент, доступный на Сайте и в Интерфейсе является объектом
              исключительных прав Чекбокса.
            </p>{" "}
            <p>
              {" "}
              11.3. Использование Сайта или Интерфейса способами, не
              предусмотренными функционалом, в том числе путем копирования
              контента, элементов дизайна строго запрещены, если иное не
              предусмотрено соглашением Сторон.
            </p>{" "}
            <p>
              {" "}
              11.4. Чекбокс имеет право без дополнительного согласования или
              уведомления:
            </p>{" "}
            <p>
              {" "}
              11.4.1. Изменять, дорабатывать и обновлять Сайт и Интерфейс.
            </p>{" "}
            <p>
              {" "}
              11.4.2. Устанавливать любые ограничения на использование Сайта и
              Интерфейса.
            </p>{" "}
            <p>
              {" "}
              11.4.3. Направлять Клиенту сообщения, уведомления, запросы,
              сведения информационного характера.
            </p>{" "}
            <p>
              {" "}
              11.4.4. Получать доступ к Заявкам Клиента для мониторинга работы
              Интерфейса.
            </p>{" "}
            <p>
              {" "}
              11.4.5. Проводить профилактические работы, влекущие
              приостановление работы Сайта и Интерфейса.
            </p>
            <h2>12. СРОК ДЕЙСТВИЯ ОФЕРТЫ</h2>
            <p>
              12.1. Оферта вступает в силу с момента акцепта Оферты и действует
              в течение года. Если ни одна из сторон за 1 месяц до окончания
              срока действия Оферты не обратится с предложением о его
              расторжении, настоящая Оферта считается пролонгированной на
              следующий год. Указанный порядок расторжения и пролонгации Оферты
              применяется на каждый последующий год.
            </p>{" "}
            <p>
              12.2. Настоящая Оферта может быть расторгнута в любое время по
              инициативе Клиента или Чекбокса путем направления другой стороне
              соответствующего уведомления электронным письмом.
            </p>
            <h2>13. ПРОЧИЕ УСЛОВИЯ</h2>
            <p>
              13.1. Оферта может быть изменена или дополнена Чекбоксом без
              уведомления Клиента в любое время. Новая редакция Оферты вступает
              в силу с момента ее размещения на Сайте и в Интерфейсе.
            </p>{" "}
            <p>
              13.2. Продолжение использования Интерфейса после внесения
              изменений и/или дополнений в Оферту означает принятие и согласие
              Клиента с такими изменениями и/или дополнениями.
            </p>{" "}
            <p>
              13.3. Оферта регулируется и толкуется в соответствии с
              законодательством Российской Федерации. Вопросы, не
              урегулированные Офертой, подлежат разрешению в соответствии с
              законодательством РФ.
            </p>{" "}
            <p>
              13.4. В случае, если какое-либо из положений Оферты окажется
              ничтожным в соответствии с законодательством Российской Федерации,
              остальные положения остаются в силе, а Оферта будет исполняться
              Сторонами в полном объеме без учета такого положения.
            </p>{" "}
            <p>
              13.5. Ничто в Оферте не может пониматься как установление между
              Клиентом и Чекбоксом отношений товарищества, отношений по
              совместной деятельности, отношений личного найма, либо каких-то
              иных отношений, прямо не названных в Оферте.
            </p>
            <h2>14. ЭЛЕКТРОННЫЙ ДОКУМЕНТООБОРОТ</h2>
            <p>
              14.1. Оферта на бумажном носителе как отдельный документ,
              подписанный обеими Сторонами, не оформляется, при этом письменная
              форма договора является соблюденной (п. 2 ст. 434 ГК РФ).
            </p>{" "}
            <p>
              14.2. Настоящая Оферта заключается путем электронного
              взаимодействия Сторон через Сайт.
            </p>{" "}
            <p>
              14.3. В соответствии с настоящим разделом об электронном
              документообороте Стороны используют простые электронные подписи
              (п. 2 ст. 160 Гражданского кодекса Российской Федерации), подписью
              для каждой из Сторон является уникальный номер мобильного телефона
              или адрес электронной почты.
            </p>{" "}
            <p>
              14.4. Стороны исходят из того, что электронное сообщение,
              направленное с электронного почтового адреса Стороны, либо номера
              мобильного телефона выражают истинное волеизъявление Стороны.
            </p>{" "}
            <p>
              14.5. Проверка подлинности и достоверности сообщения, исходящего
              от Стороны, осуществляется путем сличения адреса отправителя с
              номером телефона, указанным пользователем в качестве логина, либо
              с адреса электронной почты, предоставленной для осуществления
              контактов.
            </p>
            <h2>15. РЕКВИЗИТЫ И КОНТАКТНАЯ ИНФОРМАЦИЯ</h2>
            <p>
              Акционерное общество «Самокарт»
              <br />
              ИНН: 2310212217 КПП: 773401001
              <br />
              123308, Город Москва, вн.тер.г. муниципальный округ
              Хорошево-Мневники,
              <br />
              пр-кт Маршала Жукова, д. 4, стр. 1, помещ. 9
              <br />
            </p>{" "}
            <p>
              p/c 40702810910000706241 в АО «ТИНЬКОФФ БАНК»
              <br />
              к/с 30101810145250000974, БИК 044525974
              <br />
            </p>{" "}
            <p>
              Служба поддержки: 8 499 957-78-08
              <br />
            </p>{" "}
            <p>
              Генеральный директор: Быков Геннадий Викторович
              <br />
              Редакция от 16.08.2024
              <br />
            </p>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default PageShipping;
