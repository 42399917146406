import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom2 from "../../common/QuestionBottom2";

@inject("AppStore")
@observer
class PageContract extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className="text-p-34 color-text-black mb56 mobile-mt48">
                С нами можно работать как по договору, так и без — как вам
                удобно.
              </p>
              <p className={"text-p-34 color-text-black"}>
                Какие преимущества даёт договор?
              </p>
              <p className={"text-p-20 color-text-black"}>
                <b>Договор постоплаты</b> <br />
                + Вы сможете рассчитываться сразу за все доставки раз в месяц.
                <br />+ Мы будем предоставлять вам полный отчёт по всем
                доставкам. <br /> Это значительно упростит ваши процессы и
                расчёты.
                <br />
                + Сумма страхования выше: до 1 млн. ₽<br />
                + Индивидуальная фиксированная цена, которая не изменится <br />{" "}
                даже в высокий спрос.
                <br />
                + Скидки при определённом объёме доставок.
                <br />
                + Возможность принять участие в реферальной программе.
                <br />
              </p>
              <br />
              <p className={"text-p-20 color-text-black"}>
                <b>Агентский договор</b> с расчётно-кассовым обслуживанием даёт{" "}
                <br />
                те же преимущества, плюс ваш клиент сможет рассчитываться <br />{" "}
                за товары через нас любым удобным ему способом. Деньги будут
                поступать на ваш расчётный счёт в течение недели, за вычетом
                комиссии 2,5%.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                Договор с нами могут заключить как юридические, так и физические
                лица
              </p>
              <p className={"text-p-20 color-text-black"}>
                Чтобы заключить договор, заполните форму. В рабочее время с вами
                свяжется менеджер, уточнит детали и отправит на указанную почту
                готовый договор. Для активации договора достаточно скана с
                подписью.
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom2 variant={2} />
      </React.Fragment>
    );
  }
}

export default PageContract;
