import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "mobx-react";
import { AppStore, TrainingStore } from "./stores";
import history from "./history";
import AppApp from "./AppApp";

const stores = {
  AppStore,
  TrainingStore,
};

const App = () => (
  <Provider {...stores}>
    <Router history={history}>
      <AppApp />
    </Router>
  </Provider>
);

export default App;
