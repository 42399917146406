import React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import NavLinkApp from "../common/NavLinkApp";

@inject("AppStore")
@observer
class AppTop extends React.Component {
  render() {
    const { AppStore } = this.props;
    const { device, selectedCity, cities, linkJob, url } = AppStore;

    switch (device) {
      case "desktop":
        return (
          <Row className={"header-top"} data-url={url}>
            <Col>
              <div className={"float-left"}>
                <NavLinkApp to={"/"} name={""} className={"logo-a"}>
                  <img
                    className={"logo"}
                    src="/img/checkbox-logo.svg"
                    alt="logo"
                  />
                </NavLinkApp>
              </div>
              <div className={"float-right"}>
                <Select /* menuIsOpen={false}*/
                  className={"select-customization"}
                  value={selectedCity}
                  onChange={(ob) => AppStore.cityChange(ob)}
                  options={cities}
                  isSearchable={false}
                  placeholder={selectedCity}
                />
                <NavLinkApp
                  appTargetCode="courier"
                  to={linkJob}
                  className={"link-blue p-small ml24"}
                >
                  Стать курьером
                </NavLinkApp>
                <a
                  className={"link-blue p-small"}
                  onClick={() => AppStore.appTarget("support")}
                  href="tel:84999577808"
                >
                  Поддержка: 8 499 957 78 08
                </a>
                <a
                  className={"button order"}
                  onClick={() => AppStore.appTarget("gotoseller")}
                  href="https://seller.checkbox.ru"
                >
                  Оформить заказ
                </a>
              </div>
            </Col>
          </Row>
        );
      case "tablet":
        return (
          <Row className={"header-top"}>
            <Col>
              <div className={"float-left"}>
                <a href={"/"}>
                  <img
                    className={"logo"}
                    src="/img/checkbox-logo.svg"
                    alt="logo"
                  />
                </a>
              </div>
              <div className={"float-right"}>
                <a
                  className={"link-blue"}
                  href="tel:88005558152"
                  onClick={() => AppStore.appTarget("support")}
                >
                  <img src="/img/support.svg" alt="support" />
                </a>
                <span
                  className={"link-blue"}
                  onClick={(e) => AppStore.openMenu(e)}
                >
                  <img src="/img/burger.svg" alt="burger" />
                </span>
                <a className={"button order"} href="https://seller.checkbox.ru">
                  Оформить заказ
                </a>
              </div>
            </Col>
          </Row>
        );
      case "mobile":
        return (
          <Row className={"header-top"}>
            <Col>
              <div className={"float-left"}>
                <a href={"/"}>
                  <img
                    className={"logo"}
                    src="/img/checkbox-logo.svg"
                    alt="logo"
                  />
                </a>
              </div>
              <div className={"float-right"}>
                <a
                  className={"link-blue"}
                  href="tel:88005558152"
                  onClick={() => AppStore.appTarget("support")}
                >
                  <img src="/img/support.svg" alt="support" />
                </a>
                <span
                  className={"link-blue"}
                  onClick={(e) => AppStore.openMenu(e)}
                >
                  <img src="/img/burger.svg" alt="burger" />
                </span>
              </div>
            </Col>
          </Row>
        );
      default:
        return (
          <Alert variant={"danger"}> неопознанное устройство: {device}</Alert>
        );
    }
  }
}

export default AppTop;
