import { action, observable } from "mobx";

class TrainingStore {
  @observable myMap = null;
  @observable ymaps = null;
  @observable iconContent = "Чекбокс";

  @observable mapData = {
    center: [55.801589, 37.585962],
    zoom: 12,
  };

  @observable myLon = null;
  @observable myLat = null;

  @observable chLon = 55.804911;
  @observable chLat = 37.585962;

  @action onApiAvaliable(ymaps) {
    this.ymaps = ymaps;
    this.ymaps.geolocation
      .get({
        provider: "browser",
        mapStateAutoApply: true,
      })
      .then(
        (result) => {
          //result.geoObjects.options.set("preset", "islands#blueCircleIcon");
          this.setMyLonLat(result.geoObjects);
          //this.myMap.geoObjects.add(result.geoObjects);
          this.router();
        },
        (e) => {
          this.ymaps.geolocation
            .get({
              provider: "yandex",
              mapStateAutoApply: true,
            })
            .then(
              (result) => {
                //console.log(result);
                // result.geoObjects.options.set("preset", "islands#redCircleIcon");
                /*  result.geoObjects.get(0).properties.set({
                                            balloonContentBody: "Мое местоположение",
                                        });*/
                this.setMyLonLat(result.geoObjects);
                // this.myMap.geoObjects.add(result.geoObjects);
                this.router();
              },
              (e) => {
                console.log(e);
              }
            );
        }
      );
  }

  @action setMyLonLat(geoObjects) {
    this.myLon = geoObjects.position[0];
    this.myLat = geoObjects.position[1];
  }

  @action router() {
    this.ymaps
      .route(
        [
          { type: "wayPoint", point: [this.myLon, this.myLat] },
          { type: "wayPoint", point: [this.chLon, this.chLat] },
        ],
        {
          mapStateAutoApply: true,
        }
      )
      .then(
        (route) => {
          route.getPaths().options.set({
            balloonContentBodyLayout: this.ymaps.templateLayoutFactory.createClass(
              "$[properties.humanJamsTime]"
            ),
            strokeColor: "272BED",
            opacity: 1,
          });
          route
            .getWayPoints()
            .get(1)
            .properties.set({
              balloonContent: "чекбокс",
              iconContent: "B",
              balloonContentFooter:
                route.getHumanLength() + " за " + route.getHumanTime(),
            });

          route.getWayPoints().get(1).options.set({
            preset: "islands#blueIcon",
          });

          route
            .getWayPoints()
            .get(0)
            .properties.set({
              balloonContent: "Время в пути",
              iconContent: "A",
              balloonContentFooter:
                route.getHumanLength() + " за " + route.getHumanTime(),
            });

          route.getWayPoints().get(1).options.set({
            preset: "islands#redIcon",
          });
          // добавляем маршрут на карту
          this.myMap.geoObjects.add(route);
          setTimeout(() => {
            this.myMap.geoObjects.get(0).getWayPoints().get(0).balloon.open();
          }, 1000);
          /*this.ymaps.ready(() => {
            this.myMap.geoObjects.get(0).getWayPoints().get(0).balloon.open();
          });*/
        },
        (e) => {
          console.log(e);
        }
      );
  }
}

export default new TrainingStore();
