import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import DImg from "../../common/image-cmd/DImg";

@inject("AppStore")
@observer
class PageContactless extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>
                Оставим заказ у двери
              </p>
              <p className={"text-20-36-400 color-text-black mb16"}>
                Безопасная доставка без&nbsp;контакта с&nbsp;курьером. Просто
                добавьте опцию при
                <a className="link-a" href="https://seller.checkbox.ru/order">
                  заказе
                </a>
                {"  "}
                на&nbsp;сайте.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mb56 ">
                <DImg
                  w100={true}
                  Moscow="/img/contactless/b-d.svg"
                  CMoscow={"img-filter"}
                  MoscowM="/img/contactless/b-m.svg"
                  CMoscowM={"img-filter"}
                />
              </div>
            </Col>
          </Row>
          <Container className="w707">
            <Row>
              <Col>
                <p
                  className={
                    "text-p-20 text-center color-text-black bg-blue01  mobile-mt48"
                  }
                >
                  Все курьеры приезжают к вам и к получателю в маске и
                  перчатках.
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <DImg
                  w100={true}
                  Moscow="/img/contactless/image 21.png"
                  CMoscow={"img-filter"}
                />
              </Col>
              <Col>
                <DImg
                  w100={true}
                  Moscow="/img/contactless/image 22.png"
                  CMoscow={"img-filter"}
                />
              </Col>
            </Row>
          </Container>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageContactless;
