import React from "react";
import QuestionBottom from "../../common/QuestionBottom";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class PageTomorrow extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <p className={"text-p-34 color-text-black"}>
            Если доставка нужна в другой день, работает предзаказ до 7 дней.
          </p>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageTomorrow;
