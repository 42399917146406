import { inject, observer } from "mobx-react";
import React from "react";
import { Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";

@inject("AppStore")
@observer
class PageDeliveryTime extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <p className={"text-p-34 color-text-black"}>
            Забираем и доставляем в интервалы 60 минут
          </p>
          <p className={"text-p-20 color-text-black"}>
            Просто выберите на странице заказа удобный промежуток, когда забрать
            и когда доставить.
          </p>
          <img
            className="mtb56 img-fluid"
            src="/img/delivery-time.png"
            alt="delivery time"
          />
          <p className={"text-p-20 color-text-black mb56"}>
            Забираем с 8:00, доставляем до 23:00
          </p>

          <p className={"text-p-34 color-text-black"}>→ 15-30 минут</p>
          <p className={"text-p-20 color-text-black"}>
            рассчитываем индивидуальную фиксированную цену с учётом объёма,
            зоны, веса и сотни других параметров.
          </p>
          <p className={"text-p-34 color-text-black"}>→ 2 часа и более</p>
          <p className={"text-p-20 color-text-black"}>
            если вы готовы на более широкие интервалы, мы готовы снизить для вас
            цену.
          </p>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageDeliveryTime;
