import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import IcoLeftText2 from "../common/text/IcoLeftText2";

@inject("AppStore")
@observer
class PageJob extends React.Component {
  showScript1() {
    this.instance1.innerHTML = "";
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.id = "bx24_form_inline1";
    s.innerHTML =
      "  (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;\n" +
      "                (w[b].forms=w[b].forms||[]).push(arguments[0])};\n" +
      "                if(w[b]['forms']) return;\n" +
      "                var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());\n" +
      "                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);\n" +
      "        })(window,document,'https://checkbox.bitrix24.ru/bitrix/js/crm/form_loader.js','b24form');\n";
    s.innerHTML +=
      'b24form({"id":"34","lang":"ru","sec":"wy4a1w","type":"inline1"});';
    this.instance1.append(s);
  }

  componentDidMount() {
    const { AppStore } = this.props;
    AppStore.pageYes();
    AppStore.setSearchView(false);
    AppStore.setMetaJob();
    this.showScript();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.showScript();
  }

  showScript() {
    const { AppStore } = this.props;
    if (document.getElementById("show1script")) {
      if (window.b24form) {
        window.b24form.forms = [];
      }
      this.showScript1();
      if (window.Bitrix24FormLoader && AppStore.countForms) {
        window.Bitrix24FormLoader.init();
      }
      AppStore.countForms++;
    }
  }

  render() {
    const { AppStore } = this.props;
    const { DateNow } = AppStore;
    return (
      <React.Fragment>
        <Container key={DateNow} className="w768 mt120 job">
          <React.Fragment>
            <Row>
              <Col>
                <p className={"text-p-34 color-text-blue"}>
                  Сколько я буду получать?
                </p>
                <p className="text-p-22 color-text-black bg-blue01 inline-block mb50">
                  «Чистый» заработок курьеров до&nbsp;87&nbsp;500&nbsp;₽
                  в&nbsp;месяц.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={"text-p-34 color-text-black"}>
                  Как считается заработок?
                </p>
                <p className={"text-p-20-28 color-text-black"}>
                  <b>→ 150 ₽</b> за каждую точку — и&nbsp;доставить,
                  и&nbsp;забрать,
                  <br />
                  но&nbsp;не&nbsp;менее <b>270&nbsp;₽</b> в&nbsp;час
                  гарантированно. <br />
                  <br />
                  Доставки по&nbsp;всей Москве(МКАД + 10 км), смены от 4 часов.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={"text-p-34 color-text-black"}>А бензин?</p>
                <p className={"text-p-20-28 color-text-black"}>
                  Компенсация 4.1 ₽ / км. <br />
                  <br />В том числе расстояние от&nbsp;дома до&nbsp;первой точки
                  и от&nbsp;последнего заказа — домой. Деньгами, а
                  не&nbsp;топливными картами.
                </p>
              </Col>
            </Row>
          </React.Fragment>
          <Row>
            <Col>
              <p className="text-p-34 color-text-black mt56 mb16 mobile-mt48">
                Есть авто? Заполните анкету
              </p>

              <div className={"bg-grey-form mb56"}>
                <div id="show1script" ref={(el) => (this.instance1 = el)} />
              </div>
              <p className={"text-p-20 color-text-black mb56"}>
                {" "}
                После заполнения анкеты с вами свяжется менеджер
                и&nbsp;пригласит на&nbsp;собеседование.
              </p>
            </Col>
          </Row>
          <React.Fragment>
            <Row className="mb56">
              <Col>
                <p className={"text-p-34 color-text-black"}>Какие условия?</p>
                <ul className={"conditions"}>
                  <li>
                    <img src="/img/icon/parking.svg" alt="parking" />
                    <p className={"p-small color-text-black"}>
                      Оплата парковок
                    </p>
                  </li>
                  <li>
                    <img src="/img/icon/compensation.svg" alt="compensation2" />
                    <p className={"p-small color-text-black"}>
                      Полная компенсация налогов для самозанятых
                    </p>
                  </li>
                  <li>
                    <img src="/img/icon/studying.svg" alt="studying" />
                    <p className={"p-small color-text-black"}>
                      Обучение и поддержка каждого курьера
                    </p>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className={"text-p-34 color-text-black"}>Что возить?</p>
                <p className={"text-p-20 color-text-black mb56"}>
                  Небольшие посылки весом до 30&nbsp;кг. Документы, подарки,
                  игрушки, лёгкая электроника, бытовая химия, цветы,
                  кондитерские изделия, продукты из&nbsp;супермаркета
                  (не&nbsp;покупки). При&nbsp;работе в&nbsp;одном округе заказы
                  могут весить до&nbsp;60&nbsp;кг.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-p-34 color-text-black mb36">
                  Какие требования?
                </p>
                <IcoLeftText2
                  title="Личный автомобиль"
                  text=""
                  ico="/img/job/car.svg"
                  classNameText="mb32"
                />
                <IcoLeftText2
                  title="Грамотная речь и клиентоориентированность"
                  text="Мы хотим изменить репутацию доставки в Москве. Поэтому ждём
                    лишь тех, кто готов предложить сервис безупречного качества."
                  ico="/img/icon/speech.svg"
                  classNameText="mb60"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <a
                  href="#show1script"
                  onClick={() => AppStore.appTarget("anketabutton")}
                  className="button text-p-18 line"
                >
                  Заполнить анкету
                </a>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </React.Fragment>
    );
  }
}

export default PageJob;
