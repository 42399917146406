import React from "react";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class DImg extends React.Component {
  render() {
    const { AppStore, MoscowM, Moscow } = this.props;
    let { CMoscow, CMoscowM, w100 } = this.props;
    const { device } = AppStore;
    if (w100) {
      w100 = " w100p";
    } else {
      w100 = "";
    }
    if (!CMoscow) {
      CMoscow = "";
    }
    if (!CMoscowM) {
      CMoscowM = "";
    }

    if (MoscowM && Moscow) {
      return (
        <React.Fragment>
          {device === "mobile" ? (
            <img src={MoscowM} className={CMoscowM + w100} alt="7k" />
          ) : (
            <img src={Moscow} className={CMoscow + w100} alt="8k" />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <img src={Moscow} className={CMoscow + w100} alt="9k" />
        </React.Fragment>
      );
    }
  }
}

export default DImg;
