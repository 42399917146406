import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";

@inject("AppStore")
@observer
class PageAbout extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    const { device } = this.props.AppStore;
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>
                Мы — аутсорсинг доставки последний мили «Чекбокс»
              </p>
              <p className="text-p-20 color-text-black mb56">
                Помогая бизнесу с доставкой, мы понимаем, что помогаем людям.
                Людям, которые каждый день решают кучу проблем.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mb56">
                {device === "mobile" ? (
                  <img src="/img/Group 95.svg" alt="fashion" />
                ) : (
                  <img src="/img/Frame 23.svg" alt="fashion" />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-20 color-text-black mb58"}>
                Поэтому мы сделали так, чтобы в чеклисте «больных» вопросов хотя
                бы напротив одного пункта появилась галочка.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="h3-m color-text-black mb16">
                <img
                  className={"mr20"}
                  src="/img/clarity_car-line.svg"
                  alt="10k"
                />
                100% курьеров на легковых автомобилях с&nbsp;кондиционерами
              </p>
              <p className="text-p-20 color-text-black mb24">
                Все этапы отбора проходит 3.7% желающих. Собеседуем курьеров
                только лично, проводим тренинги каждый месяц.
              </p>

              <p className="h3-m color-text-black mb16">
                <img
                  className={"mr20"}
                  src="/img/time-sensitive.svg"
                  alt="10k"
                />
                Самые простые условия
              </p>
              <p className={"text-p-20 color-text-black mb24"}>
                Фиксированная цена вместо сложных тарифов, дополнительных
                платежей и пёстрых зон.
              </p>

              <p className="h3-m color-text-black mb16">
                <img className={"mr20"} src="/img/speed.5 2.svg" alt="10k" />
                Доставляем в день покупки
              </p>
              <p className="text-p-20 color-text-black mb56">
                У нас нет складов. Мы заберём товар в течение часа и в следующий
                час доставим покупателю.
              </p>

              <p className={"text-p-20 color-text-black"}>
                Мы называем продавцов своими <b>партнёрами</b>, потому что
                растём только&nbsp;если растут они. Поэтому мы заинтересованы в
                качестве сервиса не меньше собственника бизнеса.
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageAbout;
