import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";

@inject("AppStore")
@observer
class PagePayment extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mb56"}>
                Вы можете оплатить доставку любым удобным способом
              </p>
              <p className={"text-p-34 color-text-black"}>
                → Курьеру при получении
              </p>
              <p className={"text-p-20 color-text-black mb56"}>
                отправитель оплачивает доставку наличными.
              </p>
              <p className={"text-p-34 color-text-black"}>
                → Курьеру при отправлении
              </p>
              <p className={"text-p-20 color-text-black mb56"}>
                получатель оплачивает доставку наличными.
              </p>
              <p className={"text-p-34 color-text-black"}>→ Картой онлайн</p>
              <p className={"text-p-20 color-text-black mb56"}>
                оплата заказа на сайте при оформлении заказа.
              </p>
              <p className={"text-p-34 color-text-black"}>
                → Отправка ссылки на оплату получателю
              </p>
              <p className={"text-p-20 color-text-black mb56"}>
                клиент получит ссылку, которая направляет на страницу оплаты.
              </p>
              <p className={"text-p-34 color-text-black"}>→ По договору</p>
              <p className={"text-p-20 color-text-black mb56"}>
                раз в месяц сразу за все доставки.
              </p>
              <p className={"text-p-20 color-text-black"}>
                Способ оплаты вы выбираете в конце оформления заказа, после
                указания адресов и времени доставки.
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PagePayment;
