import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import DImg from "../common/image-cmd/DImg";

@inject("AppStore")
@inject("TrainingStore")
@observer
class PageTraining extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    const { AppStore } = this.props;
    const { device } = AppStore;

    return (
      <Container className="w768 mt120">
        <React.Fragment>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-blue"}>
                Спасибо! Мы получили вашу анкету.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56"}>
                Ждём вас на тренинг по адресу:
              </p>
              <p className={"text-p-20 color-text-black"}>
                Большая Новодмитровская 36с3, 3 вход, 3 этаж
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56"}>
                Как пройти в офис?
              </p>
              <p className={"text-p-20 color-text-black"}>
                Въезд за шлагбаум на территорию дизайн-квартала «Флакон» со
                стороны Большой Новодмитровской. Рядом с офисом много
                парковочных мест*.
              </p>
              <p>
                *Обратите внимание, что парковка платная — 40&nbsp;рублей в час.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className={device === "mobile" ? "text-center mb56" : "mb56"}
              >
                <DImg
                  w100={true}
                  Moscow="/img/training/map.svg"
                  MoscowM="/img/training/map-m.svg"
                  CMoscow={"img-filter"}
                  CMoscowM={"img-filter"}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-p-20 color-text-black">
                Далее необходимо зайти в корпус 3, вход №3, этаж 3.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={device === "mobile" ? 12 : 6}>
              <DImg
                w100={true}
                Moscow="/img/training/image 18.png"
                MoscowM="/img/training/image 18.png"
                CMoscow={"img-filter"}
                CMoscowM={"img-filter mb16"}
              />
            </Col>
            <Col sm={device === "mobile" ? 12 : 6}>
              <DImg
                w100={true}
                Moscow="/img/training/image 19.png"
                CMoscow={"img-filter"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-20 color-text-black mt56"}>
                Перед тренингом убедитесь, что вы зарегистрированы в качестве
                <a
                  target="__blank"
                  className="link-a ml-0"
                  href="https://npd.nalog.ru/"
                >
                  {" "}
                  самозанятого
                </a>
                . Это обязательное условие для сотрудничества.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56"}>
                Что брать с собой?
              </p>
              <p className={"text-p-20 color-text-black"}>
                Необходимо взять паспорт.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56"}>
                Что ещё важно знать?
              </p>
              <p className={"text-p-20 color-text-black"}>
                Приезжайте заблаговременно: учитывайте время на поиск свободной
                парковки. Просим не опаздывать, чтобы не задерживать ваших
                коллег.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56"}>
                Сохраните номер службы поддержки
              </p>
              <p className={"text-p-20 color-text-black mb16"}>
                И звоните, если вам что-то непонятно:
              </p>
              <a className="text-p-24 color-blue-a" href="tel:88005558152">
                8 800 555-81-52
              </a>
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    );
  }
}

export default PageTraining;
