import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import NavLinkApp from "../../common/NavLinkApp";
import DImg from "../../common/image-cmd/DImg";

@inject("AppStore")
@observer
class PageFashion extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w728 mt120">
          <Row>
            <Col>
              <DImg w100={true} Moscow="/img/fashion/t1.png" CMoscow="mb56" />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>→ 550 ₽ МКАД</p>
              <p className={"text-p-34 color-text-black"}>
                → 900 ₽ до 10 км от МКАД
              </p>
              <p className={"text-p-22 color-text-black"}>
                курьер доставит вещи клиенту на примерку и отвезёт обратно
                отправителю то, что не подошло.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                → 15 минут
              </p>
              <p className={"text-p-22 color-text-black"}>
                время примерки, количество вещей не ограничено.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content">
                <blockquote className="text-p-22">
                  <div className="line-left"></div>
                  Но по опыту не рекомендуем превышать 10 в одном заказе, потому
                  что физически клиенты не успевают примерить больше.
                </blockquote>
              </div>
            </Col>
          </Row>
          {/*<Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                → Возврат
              </p>
              <p className={"text-p-22 color-text-black mb56"}>
                осуществляется в тот же день, максимум на следующий.
              </p>
              <p className={"text-p-22 color-text-black mb56"}>
                Услуга примерки выбирается при&nbsp;оформлении заказа,
                в&nbsp;разделе «Дополнительно».
              </p>
              <div className="text-center">
                {device === "mobile" ? (
                  <img src="/img/fashion/image 17m.jpg" alt="fashion" />
                ) : (
                  <img src="/img/fashion/image 17.jpg" alt="fashion" />
                )}
              </div>
            </Col>
          </Row>*/}
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                → Частичный выкуп товаров и возврат
              </p>
              <p className={"text-p-22 color-text-black"}>
                Ваш клиент может платить за&nbsp;покупки при&nbsp;получении.
                Только за&nbsp;то, что&nbsp;ему&nbsp;подошло. Картой
                или&nbsp;наличными — как&nbsp;ему&nbsp;удобней. Вещи
                мы&nbsp;вернём в&nbsp;течение 24&nbsp;часов, а&nbsp;деньги
                перечислим вам&nbsp;в&nbsp;промежуток, указанный в&nbsp;
                <NavLinkApp
                  to={"/contract"}
                  name={""}
                  className="color-text-black"
                >
                  договоре
                </NavLinkApp>
                .
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-22 color-text-black mb56"}>
                Услуга примерки выбирается при&nbsp;оформлении заказа,
                в&nbsp;разделе «Дополнительно».
              </p>
              <div className="text-center">
                <DImg w100={true} Moscow="/img/fashion/b2.svg" />
              </div>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageFashion;
