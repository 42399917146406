import React from "react";

import AppHeader from "./template/AppHeader";
import AppBody from "./template/AppBody";
import AppFooter from "./template/AppFooter";

class AppApp extends React.Component {
  render() {
    const { history } = this.props;
    return (
      <React.Fragment>
        <AppHeader />
        <AppBody history={history} />
        <AppFooter />
      </React.Fragment>
    );
  }
}

export default AppApp;
