import React from "react";
import { Col, Row } from "react-bootstrap";

class BtrText extends React.Component {
  render() {
    let { DImg, text, CRow, Cp } = this.props;
    return (
      <Row className={CRow}>
        <Col lg={5} md={12}>
          {DImg}
        </Col>
        <Col lg={7} md={12}>
          <p className={Cp}>{text}</p>
        </Col>
      </Row>
    );
  }
}

export default BtrText;
