import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class PageShipping extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className={"w768"}>
          <div className="text">
            <div className="t-text t-text_md ">
              <div>
                <span>
                  Акционерное общество «Самокарт»
                  <br />
                  ОГРН 1192375009276 / ИНН 2310212217 / КПП 231001001
                  <br />
                  г. Краснодар, ул. Стахановская, д. 15, корпус 3, комната 2.
                  <br />
                </span>
                <br />
                <br />
                <br />
                <strong>
                  ОФЕРТА (ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ)
                  <br />
                  НА ОКАЗАНИЕ УСЛУГ ПЛАТФОРМЫ ДОСТАВКИ
                  <br />
                </strong>{" "}
                <br />
                Настоящее пользовательское соглашение (далее - «Оферта»), в
                соответствии с положениями ст. 437 ГК РФ является публичной
                офертой Акционерного общества «Самокарт», далее - «Чекбокс», и
                адресовано любому физическому дееспособному лицу, применяющему
                специальный налоговый режим «Налог на профессиональный доход» в
                соответствии с Федеральным законом от 27.11.2018 N 422-ФЗ, далее
                - «Курьер». Безусловным акцептом Курьером настоящей Оферты
                является Регистрация в Интерфейсе Чекбокса в качестве лица,
                желающего оказывать услуги по доставке с помощью Интерфейса
                Чекбокс.
                <br />
                <br />
                <br /> <strong>1. ОСНОВНЫЕ ТЕРМИНЫ</strong>
                <br />
                <br />
                1.1. <b>Оферта</b> – настоящее соглашение, заключаемое между
                Курьером и Чекбоксом на условиях, указанных ниже. <br />
                1.2. <b>Клиент</b> – полностью дееспособное физическое лицо,
                разместившее заявку на доставку разрешенных к перевозке
                предметов через Сайт или горячую линию Партнера. <br />
                1.3. <b>Чекбокс</b> – Акционерное общество «Самокарт» (ИНН
                2310212217, юридический адрес: Россия, г. Краснодар, ул.
                Стахановская, 15, корп. 3, комната 2), являющееся владельцем,
                оператором и администратором интерфейса (API) checkbox.ru для
                распределения и учета заказов на доставку. <br />
                1.4. <b>Курьер</b> – любое дееспособное физическое лицо,
                применяющее специальный налоговый режим «Налог на
                профессиональный доход» в соответствии с Федеральным законом от
                27.11.2018 N 422-ФЗ и желающее оказывать услуги по доставке с
                помощью Интерфейса. <br />
                1.5. <b>Партнер</b> (отправитель) - юридическое лицо, которое
                заключило либо намеревается заключить с Клиентом договор
                купли-продажи товара, содержащегося в Отправлении, владелец
                сайта или горячей линии, через которую Клиенты совершают Заказы
                и (или) оплачивают их. <br />
                1.6. <b>Интерфейс (API)</b> – web или мобильное приложение
                Чекбокса (информационная система), позволяющее Клиенту либо
                Партнеру Чекбокса заполнить все данные Заказа, необходимые для
                его осуществления (адрес отправления и получения, время
                доставки, стоимость, и т.д.), сформировать и разместить Заявку,
                а Курьеру - получить ее; а также совокупность информации о
                состоянии и движении Заявок, о новых заявках, о тарифе Курьера,
                совокупной стоимости выполненных им Заявок и размере
                вознаграждения Курьера, состояния лицевого счета, и иной
                информации и средств ее обработки, используемой сторонами для
                обмена документами и информацией в электронном виде. Доступ в
                Интерфейс осуществляется после регистрации в мобильном
                приложении через личный кабинет, и в телеграм-боте для курьеров.{" "}
                <br />
                1.7. <b>Лицевой счет</b> - способ виртуальных расчетов Сторон
                через Интерфейс, позволяющий Курьеру ежедневно открывать сессии
                для работы через Интерфейс, в том числе для приема новых Заявок,
                расчета стоимости выполненных Заявок, и отслеживания
                задолженности Сторон. <br />
                1.8. <b>Индивидуальный тариф</b> - индивидуальные финансовые
                условия, по которым рассчитывается стоимость выполнения Заявок
                на доставку Курьером, устанавливается порог отключения, порог
                включения, неснижаемый остаток. Условия индивидуального тарифа
                становятся доступны Курьеру после регистрации в Интерфейсе.{" "}
                <br />
                1.9. <b>Заявка</b> - документ, составленный Партнером в
                электронном виде, который передается по согласованному Сторонами
                протоколу взаимодействия через Интерфейс; и содержит в себе
                информацию о Заказах и адресах доставки в пределах Зоны
                доставки. <br />
                1.10. <b>Заказ (отправление)</b> - указанный в заявке предмет
                или товар (разрешенный к перевозке на территории РФ), в
                отношении которого Партнер дает Курьеру поручение доставить по
                указанному Клиентом адресу. <br />
                1.11. <b>Участие в расчетах за товар</b> - посредническая услуга
                Курьера, выполняемая от имени, по поручению и за счет Партнера,
                по участию в в наличных или безналичных расчетах за товары в
                момент доставки и передачи Заказа Клиенту (получателю,
                названному Клиентом). <br />
                1.12. <b>Адрес отправления</b> – любой адрес в г. Москва, по
                которому Курьер производит прием отправлений у лица, названного
                Партнером отправителем, для последующей доставки. <br />
                1.13. <b>Адрес доставки</b> - любой адрес в г. Москва, по
                которому Курьер производит вручение отправлений лицу, названному
                Партнером получателем отправления. <br />
                1.14. <b>Зона доставки</b> - г. Москва
                (checkbox.ru/delivery-area). <br />
                1.15. <b>Простая электронная подпись (ПЭП)</b> - электронная
                подпись (информация в электронном виде), которая посредством
                использования кодов подтверждения, паролей, sms или иных средств
                подтверждает факт формирования электронной подписи (отправления
                сообщения) определенным лицом (Партнером, Курьером, Чекбоксом).{" "}
                <br />
                1.16. <b>Уведомление</b> – электронное сообщение, направляемое
                Сторонами через Интерфейс, а также при возможности по
                электронной почте. <br />
                <br />
                <br />
                <strong>2. ПРЕДМЕТ </strong>
                <br />
                <br />
                2.1. <i>Предмет</i>. Чекбокс предлагает любому дееспособному
                физическое лицу, обладающим признаками, указанными в п.1.4.
                настоящей Оферты, используя Интерфейс, за вознаграждение
                оказывать Чекбоксу (по поручению Партнера) совокупность услуг,
                включая: (1) услуги по доставке отправлений; (2) посреднические
                услуги по участию в расчетах за доставляемые отправления, в
                момент доставки отправлений, и (3) оказание иных услуг в
                соответствии с Офертой; а Чекбокс обязуется принять указанные
                услуги и оплачивать их через Интерфейс.
                <br />
                2.2. <i>Услуги по доставке</i>: доставка указанного в Заявке
                предмета, товара (Отправления), разрешенного к перевозке
                законодательством РФ, по указанному адресу в пределах зоны
                доставки по адресу доставки.
                <br />
                2.3. <i>Посреднические (агентские) услуги</i>: участие в
                наличных и безналичных расчетах за товары, указанные в Заявке, в
                момент их доставки Клиентам. Курьер либо Чекбокс не являются
                платежными агентами, а также агентами по продаже товаров, не
                выполняют их функции. Договор купли-продажи заключается напрямую
                между Партнером и Клиентом при нажатии на Сайте кнопки “оформить
                заказ”, либо иной кнопки с соответствующим функционалом. 2.4.{" "}
                <i>Иные услуги</i>: участие в маркетинговых и иных акциях
                Чекбокса, информация о которых становится доступной в личном
                кабинете мобильного приложения, в случае их проведения.
                <br />
                2.5. <i>Квалификация Договора Оферты</i>. К правоотношениям
                между Чекбоксом и Курьером применяются положения Гражданского
                кодекса Российской Федерации об оферте (ГК РФ ч.1), о возмездном
                оказании услуг (глава 39), об агентировании (глава 52) и иные
                правовые акты, принятые в соответствии с ними. В соответствии с
                подпунктом 1 ч. 2 ст. 1 Федерального закона 103-ФЗ от 03.06.2009
                г. положения вышеуказанного закона не применяются к проведению
                расчетов за доставленные Заказы и сопутствующие услуги в рамках
                настоящего Договора, Отношения Курьера и Чекбокса в части
                исполнения поручения, указанного в пункте 2.3. настоящего
                Договора, регулируются исключительно главой 52 Гражданского
                кодекса Российской Федерации.
                <br />
                2.6. Чекбокс не предлагает к исполнению Курьерам заказы по
                доставке крупногабаритных грузов, упаковке товара, хранению
                товара, содействию в оформлении сопроводительных документов, а
                также доставке отправлений за пределы Зоны доставки.
                <br />
                2.7. <i>Собственность на Заказ</i>. Заказ, переданный Курьеру,
                остается в собственности Отправителя до доставки товара по
                адресу получения (в момент исполнения обязательства по передаче
                товара указанному получателю за исключением случаев отказа
                получателя от Заказа в момент передачи). При этом Курьер несет
                риск случайной гибели и/или повреждения Заказа с момента его
                получения по адресу отправления и до момента передачи товара по
                адресу доставки, за исключением рисков, связанных с ненадлежащей
                упаковкой отправления.
                <br />
                <br />
                <br /> <strong>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН </strong>
                <br />
                <br />
                <b>3.1. Курьер:</b> <br />
                <br />
                3.1.1. Во время действия Оферты Курьер обязуется осуществлять
                уплату всех необходимых налогов в соответствии с
                законодательством Российской Федерации, в том числе в
                соответствии с Федеральным законом от 27.11.2018 N 422-ФЗ.{" "}
                <br />
                3.1.2. Регистрация в Интерфейсе, создание личного кабинета в нем
                является акцептом Оферты Чекбокса. В этом случае Стороны не
                оформляют и не подписывают договор на бумажном носителе как
                отдельный документ, необходимая форма договора считается
                соблюденной (п.4.ст.434 ГК РФ). В целях соблюдения
                законодательства РФ о персональных данных, Курьер обязан
                подписать Заявление о присоединении к Оферте АО “Самокарт” на
                оказание услуг платформы доставки. <br />
                3.1.3. Акцепт Оферты означает, что Курьер согласен со всеми
                положениями настоящей Оферты, что Курьер производит оказание
                услуг по доставке и, в случае необходимости, по участию в
                расчетах за товар в соответствии с настоящей Офертой, а также
                дает свое разрешение на обработку своих персональных данных для
                оказания услуг в соответствии с принятыми Заявками. <br />
                3.1.4. Если Курьер не согласен с каким-либо пунктом настоящей
                Оферты, он не должен принимать Заявки в Интерфейсе Чекбокса и
                выполнять их. <br />
                3.1.5. Курьер обязан использовать собственный смартфон с
                установленным на него Интерфейсом. <br />
                3.1.6. Курьер не вправе привлекать к выполнению Заявок третьих
                лиц. <br />
                3.1.7. Курьер гарантирует возмещение в полном объеме стоимости
                Заказов (Отправлений), либо ТМЦ, выданных в соответствии с
                п.3.1.12., в случае их утраты или повреждения, в течение 3
                (трех) календарных дней с момента получения соответствующего
                требования. <br />
                3.1.8. Курьер обязуется не раскрывать предоставленную информацию
                о Клиентах и использовать ее только в целях, определенных
                настоящей Офертой и условиями согласия на обработку персональных
                данных, полученного Чекбоксом при оформлении Клиентом Заявки.{" "}
                <br />
                3.1.9. Курьер обязуется незамедлительно извещать Чекбокс об
                изменении паспортных данных, банковских реквизитов и налогового
                режима не позднее 3 (Трех) дней с момента таких изменений.{" "}
                <br />
                3.1.10. Курьер, присоединяясь к настоящей Оферте, соглашается на
                электронный документооборот и электронное взаимодействие. Номер
                мобильного телефона, указанного при регистрации в Интерфейсе,
                признаются электронной информацией, приравненной к простой
                электронной подписи. <br />
                3.1.11. Курьер не несет ответственности за качество
                доставляемого отправления, в случае наличия претензий или
                вопросов к качеству предметов в Заказе, Клиент (получатель)
                должен обращаться непосредственно к отправителю Заказа
                (Партнеру). <br />
                3.1.12. Курьер обязуется использовать фирменную одежду при
                выполнении Заявок, а также фирменные сумки, термопакеты, и так
                далее. Передача указанных предметов фиксируется Сторонами в акте
                на передачу товарно-материальных ценностей. С момента подписания
                акта Курьер несет ответственность за указанные
                товарно-материальные ценности в размере их полной стоимости.
                Замена предоставленной в соответствии с настоящим пунктом одежды
                либо предметов осуществляется Чекбоксом по мере их износа, либо
                в иных случаях, согласованных Сторонами. <br />
                3.1.13. Курьер обязуется соблюдать финансовые условия,
                предусмотренные индивидуальным тарифом, указанным в Интерфейсе в
                личном кабинете Курьера. <br />
                3.1.14. Курьер обязуется на начало выбранной смены находиться в
                пределах Зоны доставки, при этом, если есть заказ в боте —
                двигаться на заказ; если нет заказа — стоять на точке ожидания
                заказов (карта с точками содержится в телеграм-боте “Чекбокс
                Курьер”). <br />
                <br />
                <br />
                <b>3.2. Порядок оказания услуг по доставке:</b>
                <br />
                <br /> 3.2.1. Курьер обязуется забрать Отправление у отправителя
                и доставить Отправление получателю в соответствии с информацией,
                указанной в Заявке.
                <br />
                3.2.2.В случае если доставку Отправления невозможно выполнить по
                причине отсутствия получателя в месте и время, указанное в
                Заявке, либо по любой другой не зависящей от Чекбокса и Курьера
                причине, Курьер обязуется уведомить об этом Партнера и вернуть
                обратно Отправление, при этом доставка Отправления получателю
                считается выполненной и подлежит оплате.
                <br />
                3.2.3. Курьер вправе осмотреть Отправление и отказать Партнеру в
                оказании услуги доставки Отправления, если оно содержит:
                <br />
                <br />
                <br />
                <ul>
                  <li> иностранную валюту и денежные знаки РФ;</li>
                  <li>
                    живые или мертвые животные и растения (за исключением
                    составных букетов и цветариумов);
                  </li>
                  <li>
                    огнестрельное, пневматическое, газовое или холодное оружие и
                    его части, боеприпасы, фейерверки, сигнальные ракеты и
                    патроны;
                  </li>
                  <li>
                    наркотические средства, психотропные, сильнодействующие,
                    токсичные, радиоактивные, взрывчатые, ядовитые, едкие,
                    легковоспламеняющиеся и другие опасные вещества, в том числе
                    находящиеся под давлением;
                  </li>
                  <li>
                    продукты с неприятным запахом, предметы и вещества, которые
                    по своему характеру или из-за упаковки могут представлять
                    опасность для людей, загрязнять или портить (повреждать)
                    другие грузы, окружающих людей или предметы,
                  </li>
                  <li>
                    изделия из золота, серебра, других драгоценных и
                    полудрагоценных металлов и камней;
                  </li>
                  <li>
                    любые отправления, превышающие параметры 100х70х50 см;
                  </li>
                  <li>
                    любые отправления стоимостью более 1 000 000 (одного
                    миллиона рублей) рублей;
                  </li>
                  <li>любые отправления за пределы Зоны доставки;</li>
                  <li>
                    любые отправления, нарушающие “Требования к упаковке и
                    передаче товара” (доступны при создании Заявки в
                    Интерфейсе).
                  </li>
                </ul>
                3.2.4. Курьер вправе отказаться от доставки Отправления, если
                оно не соответствует описанию в Заявке или если фактические
                условия оказания услуг по доставке, о которых Курьеру сообщил
                отправитель либо Клиент (в зависимости от ситуации), не
                соответствуют другим условиям Заявки.
                <br />
                3.2.5. Доставка осуществляется по городу Москва с 06:00 до
                01:00. Чекбокс и Партнер соглашаются с тем, что время доставки
                может меняться по независящим от Курьера причинам - поломка
                автомобиля, ДТП, пробки на дорогах и т.п. В этом случае Курьер и
                Партнер согласовывают новое время доставки.
                <br />
                3.2.6. Моментом исполнения обязательств Курьера по каждой из
                Заявок является отметка Курьера в Интерфейсе при получении
                Отправления от отправителя, и отметка о передаче Отправления
                получателю.
                <br />
                <br />
                <br />
                <b>3.3. Чекбокс:</b> <br />
                <br />
                3.3.1. Чекбокс предоставляет Курьеру доступ в Интерфейс с целью
                получения информации о размещенных в Интерфейсе Заявках на
                доставку, а также для отслеживания информации о состоянии и
                движении своих Заявок, о новых заявках, размере вознаграждения
                Курьера по выполненным Заявкам, иной информации и средств ее
                обработки, используемой сторонами для информацией в электронном
                виде. Доступ в Интерфейс осуществляется после регистрации в
                мобильном приложении и (или) телеграм-боте “Чекбокс Курьер”.
                <br />
                3.3.2. Чекбокс обязуется оказывать Курьеру консультационную
                поддержку при работе в Интерфейсе по вопросам, связанным с
                регистрацией, получением и обработкой Заявок.
                <br />
                3.3.3. Чекбокс предоставляет Курьеру ККТ, необходимую для
                фискализации расчетов при выполнении Заявок и выдачи Заказов
                Клиентам/Получателям.
                <br />
                3.3.4. Чекбокс на основании данных, полученных от Партнера,
                предоставляет Курьеру доступ к информации о
                Клиентах/Получателях, в объеме, необходимом и достаточном для
                выполнения Курьером Заявок.
                <br />
                3.3.5. Чекбокс обязуется еженедельно перечислять на расчетный
                счет Курьера вознаграждение за выполненные Заявки, за период с
                понедельника по воскресенье недели, следующей перед расчетной, в
                платежный день, которым признается вторник, на основании
                еженедельных расчетов, в соответствии с данными лицевого счета
                Курьера.
                <br />
                3.3.6. В случае нарушения Курьером требований п 3.1.7. Оферты,
                Чекбокс вправе произвести зачет стоимости утраченного
                Отправления, ТМЦ и/или суммы вознаграждения, причитающегося
                Курьеру за оказание услуг в соответствии с настоящей Офертой без
                дополнительного уведомления Курьера.
                <br />
                <br />
                <strong>4. ПОРЯДОК РАСЧЕТОВ, ВОЗНАГРАЖДЕНИЕ КУРЬЕРА</strong>
                <br />
                <br />
                4.1. Лицевой счет - способ виртуальных расчетов Сторон через
                Интерфейс, позволяющий Курьеру ежедневно открывать сессии для
                работы через Интерфейс, в том числе для приема новых Заявок,
                расчета стоимости выполненных Заявок, и отслеживания
                задолженности Сторон.
                <br />
                Лицевой счет предусматривает установленный Чекбоксом порог
                отключения, который рассчитывается автоматически и зависит от
                активности Курьера в Интерфейсе; открытие новой сессии возможно
                только при соблюдении определенного индивидуальным тарифом
                баланса лицевого счета (неснижаемый остаток, порог отключения,
                порог включения).
                <br />
                4.2. Расчеты наличными денежными средствами:
                <br />
                4.2.1 Если Курьер принимает Заявку, подразумевающую оплату за
                доставку наличными денежными средствами, он от имени Чекбокса
                либо Партнера принимает денежные средства и в течение расчетной
                недели вносит их на расчетный счет Чекбокса путем
                самоинкассации.
                <br />
                4.2.2. Если Курьер принимает Заявку, подразумевающую участие в
                расчетах за товар в Отправлении, Курьер от имени и за счет
                Чекбокса либо Партнера, принимает от Клиента оплату за товар, он
                в течение 72 часов вносит их на расчетный счет Чекбокса путем
                самоинкассации.
                <br />
                4.2.3. Курьер обязуется при любых видах расчетов от имени
                Чекбокса или Партнера соблюдать требования Федеральный закон "О
                применении контрольно-кассовой техники при осуществлении
                расчетов в Российской Федерации" от 22.05.2003 N 54-ФЗ.
                <br />
                4.3. Размер вознаграждения Курьера рассчитывается на основании
                данных лицевого счета в Интерфейсе и определяется как количество
                выполненных Курьером Заявок, умноженному на тариф, по которому
                Курьер подключен к Интерфейсу. Вознаграждение может меняться по
                соглашению сторон.
                <br />
                4.4. Тариф Курьера может быть изменен путем направления Курьеру
                в электронном виде (по смс, электронной почте либо через
                Интерфейс) соответствующего уведомления, вступающего в силу на
                следующий календарный день с момента его направления.
                Продолжение выполнения обязательств в соответствии с настоящей
                Офертой признается согласием Курьера с изменениями,
                предусмотренными таким уведомлением, и подлежащим применению к
                расчетам Сторон. В случае несогласия с новыми тарифами, Курьер
                должен немедленно уведомить Чекбокс об отказе от продолжения
                использования Интерфейса.
                <br />
                4.5. При получении еженедельной выплаты за выполненные заявки от
                Чекбокса, Курьер предоставляет Чекбоксу чек из Приложения "Мой
                налог", данный документ будет являться подтверждением расходов
                Чекбокса. Акты выполненных работ при указанной форме расчетов не
                предусмотрены.
                <br />
                4.6. Все расчеты производятся в рублях РФ.
                <br />
                <br />
                <br /> <strong>5. ОТВЕТСТВЕННОСТЬ СТОРОН </strong>
                <br />
                5.1. В случае неисполнения или ненадлежащего исполнения одной из
                сторон своих обязательств, Стороны несут ответственность
                согласно Оферте и разрешаются ими путем переговоров, а в части,
                не урегулированной Офертой – в соответствии с законодательством
                РФ. При недостижении Сторонами соглашения, споры и разногласия
                подлежат разрешению в претензионном порядке. Срок ответа на
                претензию – 30 дней. <br />
                5.2.Ответственность Курьера: <br />
                5.2.1. Курьер самостоятельно несет ответственность за
                своевременную доставку и вручение Заказов Клиентам/Получателям;
                своевременный возврат Отправлений Партнеру, незамедлительное
                информирование Чекбокс о сбоях, проблемах и других форс-мажорных
                обстоятельствах, препятствующих выполнить свои обязательства
                перед Клиентами в полном объеме. <br />
                5.2.2. Курьер самостоятельно несет ответственность за
                использование личного кабинета в Интерфейсе, сохранность или
                последствия в случае утери или разглашения данных для входа в
                аккаунт третьими лицами. <br />
                5.2.3. Курьер обязуется выплатить неустойку в размере 100
                рублей, за следующие нарушения: <br />
                <br />
                <ul>
                  <li>
                    за несвоевременное отражение в Интерфейсе информации о
                    статусе выполнения Заявки;
                  </li>
                  <li>
                    за нарушение сроков доставки, вручения Заказов
                    Клиентам/Получателям при условии наличия вины Курьера;
                  </li>
                  <li>
                    за несвоевременное предоставление информации по запросам
                    Чекбокса
                  </li>
                </ul>
                5.2.4. Курьер несет ответственность перед Партнером в размере
                стоимости Заказа, указанной в Интерфейсе, если Заказ (отдельные
                Товары) по вине Курьера были утрачены или повреждены в период с
                момента получения Курьером Заказов и до момента передачи Заказа
                Клиенту/Получателю.
                <br />
                5.3. Заказ считается утраченным в следующих случаях:
                <br />
                <br />
                <ul>
                  <li>в случае хищения Заказа;</li>
                  <li>
                    если заказ не получил в Интерфейсе статуса, соответствующего
                    действиям с ним, более, чем за 6 часов;
                  </li>
                </ul>
                5.4. В случае разглашение данных Клиентов, передаваемых в
                соответствии с п.3.1.8. настоящей Оферты Курьеру в целях
                исполнения им обязанностей в соответствии с настоящей Офертой
                (адрес отправления либо получения, телефон), а также жалобы
                Клиента на некорректное поведение Курьера во время доставки,
                Курьер теряет возможность пользоваться Интерфейсом в будущем,
                личный счет и доступ в Интерфейс блокируется без возможности
                восстановления.
                <br />
                5.5. Курьер не несет ответственности:
                <br />
                <ul>
                  <li>
                    в случае если неисполнение или ненадлежащее исполнение
                    Заявки произошли по вине/по инициативе Чекбокса, либо
                    Клиента;
                  </li>
                  <li>
                    за возможное несоответствие Заказа, оформленному Клиентом по
                    качеству, количеству, ассортименту и потребительским
                    свойствам, в случае, если не были нарушены вес, упаковка,
                    целостность и форма Заказа.
                  </li>
                </ul>
                5.6. Стороны согласовали, что:
                <br />
                5.6.1. Любые требования к Чекбоксу или в связи с ним
                ограничиваются возмещением реального ущерба и ни при каких
                обстоятельствах не включают упущенную выгоду или иные косвенные
                убытки;
                <br />
                5.6.2. Чекбокс не несёт ответственности за неисполнение или
                ненадлежащее исполнение своих обязательств перед Курьером
                (соответственно Курьер - перед Клиентом), если Клиент либо
                Партнер предоставили Чекбоксу либо Курьеру недостоверные,
                неполные, неточные или вводящие в заблуждение сведения или
                информацию, в том числе при заполнении Заявки.
                <br />
                5.6.3. Курьер и Чекбокс не несут ответственности за неисполнение
                или ненадлежащее исполнение своих обязательств, если нарушение
                сроков доставки произошли вследствие обстоятельств непреодолимой
                силы или свойств вложения Заказов, о которых Курьеру и Чекбоксу
                не было известно.
                <br />
                5.6.4. Чекбокс не несет ответственности перед Курьером за
                невозможность использования Интерфейса по причинам, не зависящим
                от Чекбокса, любые действия или бездействия сервисов, сетей,
                программного обеспечения, оборудования, и т.д., а также за
                ущерб, который может быть нанесен любым устройствам и носителям
                информации Курьера в результате использования Интерфейса.
                <br />
                5.6.5. Чекбокс не несет ответственности за несанкционированное
                или неправомерное использование третьими лицами личного кабинета
                Курьера в Интерфейсе.
                <br />
                5.6.6. Чекбокс полностью отвечает за соответствие своей
                контрольно-кассовой машины, выбивающей кассовый чек для Клиента,
                требованиям законодательства Российской Федерации.
                <br />
                5.7. Неустойки и компенсация убытков, предусмотренные настоящим
                Договором, начисляются и выплачиваются только на основании
                письменной претензии пострадавшей Стороны.
                <br />
                5.8. Стороны освобождаются от ответственности за частичное или
                полное неисполнение обязательств по настоящей Оферте, если это
                неисполнение явилось следствием обстоятельств непреодолимой силы
                (форс-мажор), возникших после акцепта настоящей Оферты, в
                результате событий чрезвычайного характера, которые Курьер либо
                Чекбокс не могли ни предвидеть, ни предотвратить разумными
                мерами.
                <br />
                <br />
                <strong>6. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ </strong>
                <br />
                6.1. Чекбокс является правообладателем Сайта, Интерфейса и их
                составных частей. <br />
                6.2. Контент, доступный в Интерфейсе является объектом
                исключительных прав Чекбокса. <br />
                6.3. Чекбокс предоставляет Курьеру неисключительную лицензию на
                использование Интерфейса. Неисключительная лицензия ограничена
                «правом на использование», и ни одно из положений Оферты не
                означает передачи Курьеру исключительного права на Интерфейс
                и/или его составные части. <br />
                6.4. Использование Интерфейса способами, не предусмотренными
                функционалом, в том числе путем копирования контента, элементов
                дизайна строго запрещены, если иное не предусмотрено соглашением
                Сторон. <br />
                6.5. Чекбокс имеет право без дополнительного согласования или
                уведомления: <br />
                6.5.1. Изменять, дорабатывать и обновлять Интерфейс. <br />
                6.5.2. Устанавливать любые ограничения на использование
                Интерфейса. <br />
                6.5.3. Направлять Курьеру сообщения, уведомления, запросы,
                сведения информационного характера. <br />
                6.5.4. Получать доступ к Личному кабинету Курьера для
                мониторинга работы Интерфейса. <br />
                6.5.5. Проводить профилактические работы, влекущие
                приостановление работы Сайта и Интерфейса. <br />
                <br />
                <br /> <strong>7. ЗАПРЕЩЕННЫЕ ДЕЙСТВИЯ. БЛОКИРОВКА </strong>
                <br />
                <br /> 7.1. Курьер не вправе: <br />
                7.1.1. Использовать Интерфейс способами, прямо не
                предусмотренными Офертой.
                <br />
                7.1.2. Изменять, настраивать, переводить или создавать
                производные продукты, основанные на Интерфейсе, а также
                интегрировать Интерфейс в другие результаты интеллектуальной
                деятельности.
                <br />
                7.1.3. Предпринимать попытки обойти технические ограничения,
                установленные в Интерфейсе.
                <br />
                7.2. Чекбокс вправе незамедлительно и без дополнительного
                оповещения Курьера осуществить блокировку Личного кабинета в
                случае:
                <br />
                7.2.1. Выявления нарушения Курьером положений Оферты, в том
                числе п.3.1.8 (разглашения данных Клиентов);
                <br />
                7.2.2. Выявления нарушения Курьером положений действующего
                законодательства Российской Федерации;
                <br />
                7.2.3. Получения от третьих лиц претензии о нарушении Курьером
                их прав;
                <br />
                7.2.4. Получения соответствующих требований от государственных
                органов.
                <br />
                7.3. Блокировка означает невозможность для Курьера использовать
                функционал Интерфейса.
                <br />
                7.4. Курьер обязуется самостоятельно предпринять все усилия для
                прекращения действий, вызвавших блокировки.
                <br />
                7.5. В случае неустранения Курьером причин, вызвавших
                блокировку,Чекбокс вправе удалить аккаунт Курьера.
                <br />
                <br />
                <br />
                <strong>8. ПЕРСОНАЛЬНЫЕ ДАННЫЕ </strong>
                <br />
                <br />
                8.1. При регистрации в Интерфейсе Курьер обязан предоставить
                достоверную и актуальную информацию, предусмотренную
                регистрационной формой, для формирования Личного кабинета.
                Также, Курьер обязан подписать Заявление о присоединении к
                Оферте АО “Самокарт” на оказание услуг платформы доставки.{" "}
                <br />
                8.2. Принимая настоящую Оферту, Курьер предоставляет Чекбоксу
                свое согласие на обработку своих персональных данных в объеме,
                установленном настоящей Офертой и их использование в целях
                оказания услуг. Данные, передаваемые Чекбоксу: ФИО; Номер
                мобильного телефона Курьера, банковские данные, ИНН. <br />
                Целью обработки персональных данных Курьера является визуальное
                отображение Чекбоксом данных в смс-сообщениях, направляемых
                Клиентам с целью оповещения их о времени и параметрах доставки
                Заказа, совершенного через Интерфейс и возможной связи Клиента с
                Курьером с целью уточнения состояния доставки, а также для
                осуществления выплат за выполнение Курьером Заказов на доставку,
                на основании Оферты. <br />
                8.3. Обработка персональных данных осуществляется как с
                использованием средств автоматизации, так и без использования
                таких средств. <br />
                8.4. В рамках Оферты обрабатываются персональные данные, не
                являющиеся биометрическими: сведения о местоположении, тип и
                версия ОС, тип устройства, информация о разделах мобильного
                приложения и Интерфейса, посещаемых Курьером, ip-адрес. <br />
                8.5. Срок, в течение которого происходит обработка персональных
                данных: до момента отзыва согласия на обработку данных. Курьер
                может направить письменное заявление Чекбоксу по реквизитам,
                указанным в Оферте, с отзывом согласия на обработку данных. В
                случае отзыва Курьером или его представителем согласия на
                обработку персональных данных, Чекбокс удаляет персональные
                данные в течение 30 (тридцати) дней. <br />
                8.6. Стороны не обрабатывают персональные данные Клиентов, в
                смысле Федерального закона «О персональных данных» от
                27.07.20106 №152-ФЗ и иных нормативных актов Российской
                Федерации. <br />
                8.7. При этом Чекбокс не предоставляет Курьеру персональных
                данных Клиентов, в том числе в Интерфейсе, в целях сохранения
                конфиденциальной информации и персональных данных Клиентов.
                Чекбокс подтверждает, что получил согласие на передачу Курьеру
                имени и номера телефона Клиентов, если такие данные используются
                при работе с Интерфейсом. <br />
                8.8. Полные правила обработки данных урегулированы Политикой
                конфиденциальности Чекбокс.
                <br />
                <br />
                <strong>9. СРОК ДЕЙСТВИЯ ОФЕРТЫ </strong>
                <br />
                <br />
                9.1. Оферта действует с момента регистрации до момента удаления
                Личного кабинета в Интерфейсе.
                <br />
                9.2. Аккаунт может быть удален:
                <br />
                9.2.1. Чекбоксом по письменному требованию Курьера;
                <br />
                9.2.2. по основаниям, предусмотренным Офертой.
                <br />
                9.3. После удаления мобильного приложения (Интерфейса) Курьер
                утрачивает возможность использовать его функционал.
                <br />
                <br />
                <strong>10. ЗАВЕРЕНИЯ И ГАРАНТИИ </strong>
                <br />
                <br />
                10.1. Курьер гарантирует в установленные законодательством
                Российской Федерации сроки исполнять обязанности по исчислению и
                уплате налогов и предоставлению в налоговые инспекции
                соответствующих деклараций в полном объеме. <br />
                10.2. Курьер обязуется по запросу Чекбокса в течение 5 (пяти)
                рабочих дней предоставлять документы, подтверждающие факт уплаты
                Курьером сумм налогов за налоговые или отчетные периоды, в
                которых у Курьера возникла обязанность по уплате налогов в связи
                с настоящей Офертой, а также, подтверждающие его налоговый
                статус. <br />
                10.3. Чекбокс не является налоговым агентом по НДФЛ в смысле
                статьи 226 Налогового кодекса РФ. <br />
                <br />
                <br />
                <strong>11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ </strong>
                <br />
                <br />
                11.1. Оферта может быть изменена или дополнена Чекбоксом без
                уведомления Курьера в любое время. Новая редакция Оферты
                вступает в силу с момента ее размещения на Сайте и в Интерфейсе.
                <br />
                11.2. Продолжение использования Интерфейса после внесения
                изменений и/или дополнений в ОФерту означает принятие и согласие
                Курьера с такими изменениями и/или дополнениями.
                <br />
                11.3. Оферта регулируется и толкуется в соответствии с
                законодательством Российской Федерации. Вопросы, не
                урегулированные Офертой, подлежат разрешению в соответствии с
                законодательством РФ.
                <br />
                11.4. В случае, если какое-либо из положений Оферты окажется
                ничтожным в соответствии с законодательством Российской
                Федерации, остальные положения остаются в силе, а Оферта будет
                исполняться Сторонами в полном объеме без учета такого
                положения.
                <br />
                11.5. Ничто в Оферте не может пониматься как установление между
                Курьером и Чекбоксом отношений товарищества, отношений по
                совместной деятельности, отношений личного найма, либо каких-то
                иных отношений, прямо не названных в Оферте.
                <br />
                <br /> <strong>12. БАНКОВСКИЕ РЕКВИЗИТЫ </strong>
                <br />
                <br />
                Чекбокс: АО «САМОКАРТ»
                <br />
                ИНН 2310212217 КПП 231001001
                <br />
                г. Краснодар, ул. Стахановская, 15, корп. 3, комната 2.
                <br />
                р/с 40702810026020005282
                <br />
                в филиале «Ростовский» АО АЛЬФА-БАНК, г. Краснодар
                <br />
                к/с 30101810500000000207
                <br />
                БИК 046015207
                <br />
                e-mail: info@samocart.ru
                <br />
                Служба поддержки: +7 (918) 060-03-96
                <br />
                <br />
                Генеральный директор: Евдаков Кирилл Сергеевич, k@samocart.ru
                <br />
                <br />
                <br />
                <br />
                Редакция Оферты от 22.03.2021 года.
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default PageShipping;
