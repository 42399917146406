import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";

import PageIndex from "../pages/PageIndex";
import PageCost from "../pages/suggest/PageCost";
import PageDeliveryArea from "../pages/suggest/PageDeliveryArea";
import PageDeliveryTime from "../pages/suggest/PageDeliveryTime";
import PageTomorrow from "../pages/suggest/PageTomorrow";
import PageOthers from "../pages/suggest/PageOthers";
import PageHtml from "../pages/Page404";
import PageTalk from "../pages/suggest/PageTalk";
import PageJob from "../pages/PageJob";
import PageJobSpb from "../pages/PageJobSpb";
import PageShipping from "../pages/PageShipping";
import PageShippingKM from "../pages/PageShippingKM";
import PageShareholders from "../pages/PageShareholders";
import Page500 from "../pages/Page500";
import PageCancel from "../pages/suggest/PageCancel";
import PageContacts from "../pages/suggest/PageContacts";
import PageAbout from "../pages/suggest/PageAbout";

import PageUndeliverable from "../pages/suggest/PageUndeliverable";
import PageInterCity from "../pages/suggest/PageInterCity";
import PageInsurance from "../pages/suggest/PageInsurance";

import PagePayment from "../pages/suggest/PagePayment";
import PageCash from "../pages/suggest/PageCash";
import PageContract from "../pages/suggest/PageContract";
import PageTracking from "../pages/suggest/PageTracking";
import PageFashion from "../pages/suggest/PageFashion";

import PageForm from "../pages/suggest/PageForm";
import PageTraining from "../pages/PageTraining";
import PageRko from "../pages/suggest/PageRko";
import PageCars from "../pages/suggest/PageCars";
import PageContactless from "../pages/suggest/PageContactless";
import PageApi from "../pages/suggest/PageApi";
import PageShippingVoronezh from "../pages/PageShippingVoronezh";
import PagePerishable from "../pages/suggest/PagePerishable";
import PageConfidentiality from "../pages/PageConfidentiality";
import PageRefer from "../pages/PageRefer";
import PageReferCourier from "../pages/PageReferCourier";
import PagePrivacyPolicy from "../pages/PagePrivacyPolicy";

@inject("AppStore")
@observer
class AppBody extends React.Component {
  render() {
    const { AppStore, history } = this.props;
    const { url } = AppStore;
    return (
      <div id="work-area" key={url}>
        <Switch>
          <Route history={history} exact path="/" children={<PageIndex />} />
          <Route history={history} exact path="/cost" children={<PageCost />} />
          <Route history={history} exact path="/form" children={<PageForm />} />

          <Route
            history={history}
            exact
            path="/refer"
            children={<PageRefer />}
          />
          <Route
            history={history}
            exact
            path="/privacy-policy"
            children={<PagePrivacyPolicy />}
          />
          <Route
            history={history}
            exact
            path="/refer/:tel"
            children={<PageRefer />}
          />
          <Route
            history={history}
            exact
            path="/refer-courier"
            children={<PageReferCourier />}
          />
          <Route
            history={history}
            exact
            path="/refer-courier/:tel"
            children={<PageReferCourier />}
          />

          <Route
            history={history}
            exact
            path="/training"
            children={<PageTraining />}
          />
          <Route
            history={history}
            exact
            path="/payment"
            children={<PagePayment />}
          />
          <Route history={history} exact path="/cash" children={<PageCash />} />
          <Route
            history={history}
            exact
            path="/contract"
            children={<PageContract />}
          />
          <Route
            history={history}
            exact
            path="/insurance"
            children={<PageInsurance />}
          />
          <Route
            history={history}
            exact
            path="/about"
            children={<PageAbout />}
          />
          <Route
            history={history}
            exact
            path="/delivery-area"
            children={<PageDeliveryArea />}
          />
          <Route
            history={history}
            exact
            path="/undeliverable"
            children={<PageUndeliverable />}
          />
          <Route
            history={history}
            exact
            path="/delivery-time"
            children={<PageDeliveryTime />}
          />
          <Route
            history={history}
            exact
            path="/tomorrow"
            children={<PageTomorrow />}
          />
          <Route
            history={history}
            exact
            path="/contacts"
            children={<PageContacts />}
          />
          <Route
            history={history}
            exact
            path="/contactless"
            children={<PageContactless />}
          />
          <Route history={history} exact path="/api" children={<PageApi />} />
          <Route
            history={history}
            exact
            path="/cancel"
            children={<PageCancel />}
          />
          <Route history={history} exact path="/cars" children={<PageCars />} />
          <Route history={history} exact path="/rko" children={<PageRko />} />
          <Route
            history={history}
            exact
            path="/fashion"
            children={<PageFashion />}
          />
          <Route
            history={history}
            exact
            path="/intercity"
            children={<PageInterCity />}
          />
          <Route
            history={history}
            exact
            path="/tracking"
            children={<PageTracking />}
          />
          <Route
            history={history}
            exact
            path="/others"
            children={<PageOthers />}
          />
          <Route history={history} exact path="/talk" children={<PageTalk />} />
          <Route history={history} exact path="/job" children={<PageJob />} />
          <Route
            history={history}
            exact
            path="/job-spb"
            children={<PageJobSpb />}
          />
          <Route
            history={history}
            exact
            path="/perishable"
            children={<PagePerishable />}
          />
          <Route
            history={history}
            exact
            path="/shipping"
            children={<PageShipping />}
          />
          <Route
            history={history}
            exact
            path="/courier-offer-moscow"
            children={<PageShippingKM />}
          />

          <Route
            history={history}
            exact
            path="/oferta-voronezh"
            children={<PageShippingVoronezh />}
          />

          <Route
            history={history}
            exact
            path="/shareholders"
            children={<PageShareholders />}
          />
          <Route
            history={history}
            exact
            path="/confidentiality"
            children={<PageConfidentiality />}
          />
          <Route
            history={history}
            exact
            path="/500.html"
            children={<Page500 />}
          />
          <Route history={history} children={<PageHtml />} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(AppBody);
