import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

@inject("AppStore")
@observer
class PageForm extends React.Component {
  showScript1() {
    this.instance1.innerHTML = "";
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.id = "bx24_form_inline1";
    s.innerHTML =
      "  (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;\n" +
      "                (w[b].forms=w[b].forms||[]).push(arguments[0])};\n" +
      "                if(w[b]['forms']) return;\n" +
      "                var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());\n" +
      "                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);\n" +
      "        })(window,document,'https://checkbox.bitrix24.ru/bitrix/js/crm/form_loader.js','b24form');\n";
    s.innerHTML +=
      'b24form({"id":"32","lang":"ru","sec":"s0zu1p","type":"inline1"});';
    this.instance1.append(s);
  }

  componentDidMount() {
    const { AppStore } = this.props;
    AppStore.pageYes();
    AppStore.setSearchView(false);
    this.showScript();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.showScript();
  }

  showScript() {
    const { AppStore } = this.props;
    if (document.getElementById("show1script")) {
      if (window.b24form) {
        window.b24form.forms = [];
      }
      this.showScript1();
      if (window.Bitrix24FormLoader && AppStore.countForms) {
        window.Bitrix24FormLoader.init();
      }
      AppStore.countForms++;
    }
  }

  render() {
    const { AppStore } = this.props;
    const { DateNow, device } = AppStore;
    let h = "432";
    if (device === "mobile") {
      h = "162";
    }
    return (
      <React.Fragment>
        <Container key={DateNow} className="w768 mt120">
          <React.Fragment>
            <Row>
              <Col>
                <p className="text-p-34 color-text-black">
                  Мы будем рады видеть вас в команде курьеров «Чекбокса»
                </p>
                <p className="text-p-20 ">Осталось только заполнить анкету</p>
                <p className="text-p-20 mt56">
                  Чтобы вам было проще, мы подготовили пошаговую инструкцию.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <iframe
                  title="IYz9XXvLE08"
                  width="100%"
                  height={h}
                  src="https://www.youtube.com/embed/150a-SYC3BI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={"bg-grey-form mt56"}>
                  <div id="show1script" ref={(el) => (this.instance1 = el)} />
                </div>
                <p className="text-p-20 color-text-black mt56  ">
                  Перед тренингом обязательно установите мессенджер Telegram.
                  Без&nbsp;него вы не сможете выполнять заказы.
                </p>
                <div className="text-center">
                  <a
                    href="https://tlgrm.ru/apps"
                    target="__blank"
                    className=" bt-tg  text-center"
                  >
                    <img
                      src="/img/form/telegram.svg"
                      className="mr7 mb2"
                      style={{ height: "21px", width: "26px" }}
                      alt="telegram"
                    />
                    Установить Telegram
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="page-form">
              <Col>
                <p className="text-p-34 color-text-black mt56">
                  Если у вас остались вопросы, звоните
                </p>
                <p className="text-p-22 color-text-black">
                  Наша Служба поддержки поможет вам разобраться
                </p>
                <p className="color-text-blue">
                  <a className="text-p-24 color-blue-a" href="tel:88005558152">
                    8 800 555-81-52
                  </a>
                </p>
              </Col>
            </Row>
          </React.Fragment>
        </Container>
      </React.Fragment>
    );
  }
}

export default PageForm;
