import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";

import "./css/app.css";
import "./css/desktop.css";
import "./css/tablet.css";
import "./css/mobile.css";

import { AppStore } from "./stores";
import firebase from "firebase/app";

(async () => {
  try {
    const configResponse = await axios.get("/config.json");

    if (!configResponse || !configResponse.data) {
      throw new Error("Failed to load config");
    }

    const config = configResponse.data;

    firebase.initializeApp(config);

    AppStore.inits();
    ReactDOM.render(<App />, document.getElementById("root"));
    serviceWorker.unregister();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
})();
