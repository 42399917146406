import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";

@inject("AppStore")
@observer
class PageCost extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    const { AppStore } = this.props;
    const { device } = AppStore;
    let h = "657px";
    if (device === "mobile") {
      h = "440px";
    }
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>→ 400 ₽ и меньше</p>
              <p className={"text-p-20 color-text-black"}>
                в пределах МКАД, часовые интервалы, вес до 30 кг.
                в&nbsp;пределах МКАД, часовые интервалы, вес&nbsp;до 30&nbsp;кг.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                → 700 ₽
              </p>
              <p className={"text-p-20 color-text-black"}>
                10 км. от МКАД, часовые интервалы, вес до 30 кг.
                <br />
                <br />
                <br />
                По&nbsp;договору возможна индивидуальная фиксированная цена.
                <br />
                Например, более низкая — с&nbsp;учётом ваших объёмов.
              </p>
            </Col>
          </Row>
          <Row>
            <iframe
              className="mt56"
              title={"ya"}
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A05883c19bdf7f32a64cb578e921b4f4ebd25ad85758065346187946e4b361e9d&amp;source=constructor"
              width="100%"
              height={h}
              frameBorder="0"
            ></iframe>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                → Расчёт индивидуальной фиксированной цены
              </p>
              <p className={"text-p-20 color-text-black"}>
                Именно под ваш объём, зону, вес, скорость подачи курьера и сотни
                других параметров.
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageCost;
