import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import SuggestList from "../common/SuggestList";
import AppTop from "./AppTop";
import { YMInitializer } from "react-yandex-metrika";

@inject("AppStore")
@observer
class AppHeader extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.props.AppStore.onResize);
  }

  render() {
    const { AppStore } = this.props;
    const {
      search,
      searchPlaceholder,
      fontSize,
      device,
      searchPlaceholderDefault,
      isSearchView,
    } = AppStore;
    return (
      <React.Fragment>
        <header>
          <YMInitializer accounts={[66017959]} />
          <Container>
            <AppTop />
            {isSearchView ? (
              <Row>
                <Col>
                  {search.length ? null : (
                    <p className={"question"}>
                      Сервис доставки, который отвечает вашим запросам
                    </p>
                  )}
                  <div className={"search-container"}>
                    {device === "mobile" ? (
                      <span id={"font-size-input"}>{search}</span>
                    ) : null}
                    {device === "mobile" ? (
                      <span id={"font-size-input-placeholder"}>
                        {searchPlaceholder}
                      </span>
                    ) : null}
                    {device === "mobile" ? (
                      <div className={"mobile-search"}>
                        <input
                          type="text"
                          value={search}
                          id="input-search"
                          placeholder={searchPlaceholderDefault}
                          autoFocus={"autofocus"}
                          className={"input-search-m"}
                          onChange={(e) => AppStore.onChangeSearch(e)}
                          style={fontSize ? { fontSize: fontSize + "px" } : {}}
                        />
                        {searchPlaceholder && device === "mobile" ? (
                          <div
                            onClick={() => AppStore.activeFocus()}
                            style={
                              fontSize ? { fontSize: fontSize + "px" } : {}
                            }
                            className={"active-placeholder-m"}
                          >
                            {searchPlaceholder}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <textarea
                        placeholder={searchPlaceholderDefault}
                        id="textarea-search"
                        rows={AppStore.rowSearch}
                        autoFocus={"autofocus"}
                        value={search}
                        className={"textarea-search"}
                        onChange={(e) => AppStore.onChangeSearch(e)}
                      />
                    )}
                    <hr className={"textarea-line"} />
                    {searchPlaceholder && device !== "mobile" ? (
                      <div
                        onClick={() => AppStore.activeFocus()}
                        className={"active-placeholder"}
                      >
                        {searchPlaceholder}
                      </div>
                    ) : null}
                    {search.length ? (
                      <img
                        className={"search-clear"}
                        onClick={(e) => AppStore.searchClear()}
                        src="/img/burger2.svg"
                        alt="close.svg"
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
            ) : null}
          </Container>
          <SuggestList />
        </header>
      </React.Fragment>
    );
  }
}

export default AppHeader;
