import React from "react";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class FormMoreInfo24 extends React.Component {
  showScript1() {
    this.instance1.innerHTML = "";
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.id = "bx24_form_inline1";
    s.innerHTML =
      "        (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;\n" +
      "                (w[b].forms=w[b].forms||[]).push(arguments[0])};\n" +
      "                if(w[b]['forms']) return;\n" +
      "                var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());\n" +
      "                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);\n" +
      "        })(window,document,'https://checkbox.bitrix24.ru/bitrix/js/crm/form_loader.js','b24form');\n" +
      "\n";

    s.innerHTML +=
      'b24form({"id":"46","lang":"ru","sec":"yxva5k","type":"inline1"});\n';
    this.instance1.append(s);
  }

  componentDidMount() {
    this.showScript();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.showScript();
  }

  showScript() {
    const { AppStore } = this.props;
    if (document.getElementById("show1script")) {
      if (window.b24form) {
        window.b24form.forms = [];
      }
      this.showScript1();
      if (window.Bitrix24FormLoader && AppStore.countForms) {
        window.Bitrix24FormLoader.init();
      }
      AppStore.countForms++;
    }
  }

  render() {
    const { variant } = this.props;
    return (
      <div>
        <div className={"bg-grey-form "}>
          {variant === 1 ? (
            <h3 className={"h3-m"}>Нужно больше информации?</h3>
          ) : (
            <h3 className={"h3-m"}>Оставьте заявку, и мы вам позвоним</h3>
          )}
          <div id="show1script" ref={(el) => (this.instance1 = el)} />
        </div>
      </div>
    );
  }
}

export default FormMoreInfo24;
