import React from "react";
import { inject } from "mobx-react";

@inject("AppStore")
class NavLinkApp extends React.Component {
  render() {
    const {
      to,
      children,
      AppStore,
      className,
      name,
      close = false,
      appTargetCode = null,
    } = this.props;

    return (
      <a
        className={className}
        onClick={(e) => AppStore.toLink(e, to, name, close, appTargetCode)}
        href={to}
      >
        {children}
      </a>
    );
  }
}

export default NavLinkApp;
