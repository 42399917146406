import { inject, observer } from "mobx-react";
import React from "react";
import { Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";

@inject("AppStore")
@observer
class PageDeliveryArea extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    const { AppStore } = this.props;
    const { device } = AppStore;
    let h = "657px";
    if (device === "mobile") {
      h = "440px";
    }
    let src = "";

    src =
      "https://yandex.ru/map-widget/v1/?um=constructor%3A05883c19bdf7f32a64cb578e921b4f4ebd25ad85758065346187946e4b361e9d&amp;source=constructor";

    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <iframe
            title={"ya"}
            src={src}
            width="100%"
            height={h}
            frameBorder="0"
          ></iframe>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageDeliveryArea;
