import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import DImg from "../../common/image-cmd/DImg";
import BtrText from "../../common/text/BtrText";

@inject("AppStore")
@observer
class PageTracking extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    let mr = "ml-15 h108";
    let CRow = "mb30";
    let ClassPText = "h3-m text-p-20 color-text-black mtl-btr";
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-20 color-text-black"}>
                Историю заказов, а также статус текущих вы можете посмотреть
                в&nbsp;личном кабинете. Для этого нужно авторизоваться, повторно
                нажав «Оформить заказ».
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center mtb56">
                <DImg w100={true} Moscow="/img/tracking/v1m.svg" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mb10"}>Cтатус заказа</p>
            </Col>
          </Row>
          <BtrText
            DImg={
              <DImg
                w100={false}
                Moscow="/img/tracking/stat1.svg"
                CMoscow={mr}
              />
            }
            text="«Курьер назначен» — задача создана, исполнитель найден."
            CRow={CRow}
            Cp={ClassPText}
          />
          <BtrText
            DImg={
              <DImg
                w100={false}
                Moscow="/img/tracking/stat2.svg"
                CMoscow={mr}
              />
            }
            text="«Курьер в пути» —  исполнитель отправился за заказом."
            CRow={CRow}
            Cp={ClassPText}
          />
          <BtrText
            DImg={
              <DImg
                w100={false}
                Moscow="/img/tracking/stat3.svg"
                CMoscow={mr}
              />
            }
            text="«Курьер забрал» — заказ у&nbsp;курьера, теперь он отправляется к&nbsp;клиенту."
            CRow={CRow}
            Cp={ClassPText}
          />
          <BtrText
            DImg={
              <DImg
                w100={false}
                Moscow="/img/tracking/stat4.svg"
                CMoscow={mr}
              />
            }
            text="«Доставка завершена» — заказ доставлен клиенту."
            CRow={CRow}
            Cp={ClassPText}
          />
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageTracking;
