import React from "react";
import { inject, observer } from "mobx-react";
import { Container } from "react-bootstrap";
import NavLinkApp from "./NavLinkApp";

@inject("AppStore")
@observer
class SuggestList extends React.Component {
  componentDidMount() {
    window.addEventListener("keydown", this.props.AppStore.onKeydown);
  }

  render() {
    const { AppStore } = this.props;
    const { suggest } = AppStore;
    if (suggest.length) {
      return (
        <Container>
          <ul className={"suggest-list"}>
            {suggest.map((s) => (
              <li
                onClick={(e) => AppStore.setSearch(s.name)}
                key={"suggest-" + s.name}
                className={s.active ? "active" : null}
              >
                <NavLinkApp to={s.url} name={s.name}>
                  {s.name}
                  {s.active ? (
                    <img
                      className={"enter"}
                      src="/img/icon/Vector 6.svg"
                      alt="Vector 6.svg"
                    />
                  ) : null}
                </NavLinkApp>
              </li>
            ))}
          </ul>
        </Container>
      );
    } else {
      return null;
    }
  }
}

export default SuggestList;
