import { inject, observer } from "mobx-react";
import React from "react";
import { Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import { Map, Placemark, YMaps } from "react-yandex-maps";

@inject("AppStore")
@observer
class PageContacts extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    const { AppStore } = this.props;

    return (
      <React.Fragment>
        <Container className=" mt120">
          <div className="ymap-service">
            <div className="c-balloon">
              <a className="mb40 link" href="mail:hello@checkbox.ru">
                hello@checkbox.ru
              </a>
              <span>Для СМИ —</span>
              <a className="mb40" href="mail:pr@checkbox.ru">
                pr@checkbox.ru
              </a>
              <br />
              <br />
              <p className="mb40">
                127015, Москва, Большая
                <br /> Новодмитровская улица, 36с3,
                <br />3 вход, 3 этаж
              </p>
              <p className="mb40">
                Часы работы офиса:
                <br />
                ПН-ПТ С 09:00-21:00
              </p>
              <a href="tel:88005558152">8 800 555-81-52</a>
            </div>
            <YMaps>
              <Map
                defaultState={{ center: [55.801589, 37.585962], zoom: 10 }}
                height={700}
                width={"100%"}
                instanceRef={(ref) => (AppStore.myMap = ref)}
              >
                <Placemark geometry={[55.804911, 37.585962]} />
              </Map>
            </YMaps>
          </div>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageContacts;
