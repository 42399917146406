import React from "react";

import { Col, Row } from "react-bootstrap";
import doc1 from "../docx/Шаблон_Договора АО Самокарт - Ритейлер с участием в расчетах.docx";
import doc2 from "../docx/Шаблон_Договор_АО_Самокарт_Москва_доставка_без_участия_в_расчетах.docx";

class DownloadDocx extends React.Component {
  render() {
    return (
      <Row>
        <Col>
          <Row>
            <p className={"text-p-15 color-text-black mt40 ml50"}>
              Вы можете ознакомиться с шаблонами договоров
            </p>
          </Row>
          <Row className="ml20">
            <Col>
              <a
                className="docx-download"
                rel="noopener noreferrer"
                target="_blank"
                href={doc2}
              >
                Агентский договор
              </a>
              <a
                className="docx-download ml40"
                rel="noopener noreferrer"
                target="_blank"
                download
                href={doc1}
              >
                Договор с РКО
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default DownloadDocx;
