import React from "react";
import Container from "react-bootstrap/Container";
import { inject, observer } from "mobx-react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import img18 from "../../src/img/footer/18.svg";

@inject("AppStore")
@observer
class AppFooter extends React.Component {
  render() {
    const { AppStore } = this.props;
    const {
      device,
      noFooter,
      popupCity,
      selectedCity,
      cities,
      showMenu,
      url,
      spbJ,
    } = AppStore;

    return (
      <React.Fragment>
        <div className={showMenu ? "b-popup-menu" : "b-popup-menu-none"}>
          <div
            className="b-popup-content-blue"
            onClick={(e) => AppStore.closeMenu(e)}
          ></div>
          <div className="b-popup-content-menu">
            <Row className={"relative"}>
              <Col>
                <img
                  className={"close"}
                  src="/img/burger2.svg"
                  onClick={(e) => AppStore.closeMenu(e)}
                  alt="close"
                />
                {selectedCity.code === "Moscow" ? (
                  <p className="p-text-24-40 color-text-black">→ Москва</p>
                ) : (
                  <p
                    onClick={(e) => AppStore.setSelectedCity("Moscow")}
                    className="p-text-24-40 color-text-blue"
                  >
                    Москва
                  </p>
                )}

                {spbJ ? (
                  selectedCity.code === "spb" ? (
                    <p className="p-text-24-40 color-text-black">
                      → Санкт-Петербург
                    </p>
                  ) : (
                    <p
                      onClick={(e) => AppStore.setSelectedCity("spb")}
                      className="p-text-24-40 color-text-blue"
                    >
                      Санкт-Петербург
                    </p>
                  )
                ) : null}
                <a
                  href="/job"
                  onClick={(e) => {
                    AppStore.appTarget("courier");
                    AppStore.closeMenu();
                    AppStore.toGo(e, "/job");
                  }}
                  className={"p-text-24-40 color-text-blue mtb32"}
                >
                  Стать курьером
                </a>
                <a
                  className={"p-text-24-40 color-text-blue"}
                  onClick={() => AppStore.appTarget("support")}
                  href="tel:84999577808"
                >
                  Поддержка: <span> 8 499 957 78 08</span>
                </a>
              </Col>
            </Row>
          </div>
        </div>
        {popupCity ? (
          <React.Fragment>
            <div className="b-popup-city">
              <div className="b-popup-content-city">
                <div className={"relative height-100"}>
                  <Row className={"relative"}>
                    <Col>
                      <img
                        className={"close"}
                        src="/img/Vector 5.svg"
                        alt="close"
                      />
                      <p className="h3-m mb40">
                        Мы правильно определили ваш город?
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        className={"select-customization"}
                        value={selectedCity}
                        onChange={(ob) => AppStore.cityChange(ob)}
                        options={cities}
                        isSearchable={false}
                        placeholder={selectedCity}
                      />
                      <div className="hr1grey15" />
                    </Col>
                  </Row>

                  <span
                    onClick={(e) => AppStore.closePopupCity(e)}
                    className={"c-button"}
                  >
                    Ок
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {noFooter ? null : (
          <React.Fragment>
            <footer>
              <Container>
                <Row>
                  <Col>
                    <span className={"copy-rating float-left"}>
                      <img src={img18} className="img18" alt="18+" /> © 2020
                      Checkbox
                    </span>
                    <span className={"float-left pt10 ml44"}>
                      <a
                        onClick={(e) => {
                          AppStore.toGo(e, "/shareholders");
                          AppStore.scrollUp();
                        }}
                        className={"mr10 link"}
                        href={"/shareholders"}
                      >
                        Акционерам
                      </a>
                      <a
                        onClick={(e) => {
                          AppStore.toGo(e, "/shipping");
                          AppStore.scrollUp();
                        }}
                        className={"mr10 link"}
                        href={"/shipping"}
                      >
                        Оферта на доставку
                      </a>
                      <a
                        onClick={(e) => {
                          AppStore.toGo(e, "/privacy-policy");
                          AppStore.scrollUp();
                        }}
                        className={"link"}
                        href={"/privacy-policy"}
                      >
                        Политика персональных данных
                      </a>
                    </span>
                    <span className={"float-right footer-social"}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://facebook.com/checkbox.delivery"
                      >
                        <img src="/img/footer/facebook 1.svg" alt="facebook" />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/checkbox"
                      >
                        <img src="/img/footer/telegram 1.svg" alt="telegram" />
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/checkbox.delivery"
                      >
                        <img
                          src="/img/footer/instagram 1.svg"
                          alt="instagram"
                        />
                      </a>
                    </span>
                  </Col>
                </Row>
              </Container>
            </footer>
            {device === "mobile" && url !== "/form" && url !== "/training" ? (
              <a
                className={"button order-mobil"}
                href="https://seller.checkbox.ru"
              >
                Оформить заказ
              </a>
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default AppFooter;
