import React from "react";
import { inject, observer } from "mobx-react";
import { Col, Container, Row } from "react-bootstrap";
import FormMoreInfo24 from "./FormMoreInfo24";
import NavLinkApp from "./NavLinkApp";

@inject("AppStore")
@observer
class QuestionBottom extends React.Component {
  render() {
    const { AppStore, mt = "mt120", question = true, variant = 1 } = this.props;
    return (
      <Container className={"w768 " + mt}>
        <Row>
          <Col>
            <FormMoreInfo24 variant={variant} />
          </Col>
        </Row>
        {question ? (
          <React.Fragment>
            <Row>
              <Col className={"my-auto"}>
                <div className={"block-button-primary"}>
                  <img
                    src="/img/icon/question.svg"
                    className={"mr26px va-b"}
                    alt="question"
                  />
                  <NavLinkApp
                    to={"/"}
                    name={"Задать другой вопрос"}
                    appTargetCode={"tanoquest"}
                    className={"h3-m color-text-blue"}
                  >
                    Задать другой вопрос
                  </NavLinkApp>
                  <img
                    className={"float-right"}
                    src="/img/arrow.svg"
                    alt="arrow2"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={"block-button-primary"}>
                  <img
                    src="/img/icon/order.svg"
                    className={"mr26px va-b"}
                    alt="question"
                  />
                  <a
                    onClick={() => AppStore.appTarget("bezreg")}
                    href="https://seller.checkbox.ru"
                    className={"h3-m color-text-blue"}
                  >
                    Оформить заказ без регистрации
                  </a>
                  <img
                    className={"float-right "}
                    src="/img/arrow.svg"
                    alt="right"
                  />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        ) : null}
      </Container>
    );
  }
}

export default QuestionBottom;
