import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class PageConfidentiality extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className={"w768 p-top-none"}>
          <div className="text">
            <div className="t-text t-text_md ">
              <div>
                <b>Политика конфиденциальности</b> <br /> <br />
                <p>
                  Мобильное приложение «Чекбокс курьер» (далее — приложение)
                  предоставляет возможность курьерам, используя смартфон или
                  планшет (далее — устройство) выбрать смены, ознакомиться с
                  расписанием и получить информацию в соответствии с
                  функционалом приложения. Этот документ даёт разъяснения по
                  поводу объёма информации, собираемой приложением устройства,
                  целей сбора информации и круга получателей собранной
                  информации.
                </p>
                <br />
                <b>1. Собираемая информация:</b>
                <p> Имя и номер мобильного телефона пользователя</p>
                <br />
                <b>Цели сбора:</b>
                <p>
                  {" "}
                  Подтверждение контактных данных пользователя для последующей
                  коммуникации посредством SMS и телефонных звонков.
                </p>
                <br />
                <b>Примечание:</b>
                <p>
                  Введённый номер телефона не обязательно должен соответствовать
                  номеру устройства, на котором установлено приложение.
                  Приложение может использоваться в полном объёме
                  функциональности на устройствах без SIM-карты, если при входе
                  введён номер, для которого пользователь знает или может
                  получить код подтверждения посредством SMS. Номер телефона
                  пользователя не передаётся третьим лицам. Имя, указанное при
                  регистрации, может быть любым, и используется исключительно
                  для обращения к пользователю.
                </p>
                <br />
                <b> 2. Собираемая информация:</b>
                <p>Идентификатор устройства.</p>
                <br />
                <b> Цели сбора:</b>
                <p>Внутренний учёт пользователей приложения.</p>
                <br />
                <b> Примечание:</b>
                <p>
                  Обезличенный идентификатор устройства предоставляется самим
                  устройством или рассчитывается приложением, исходя из
                  различных уникальных данных устройства. Идентификатор
                  устройства не содержит никаких персональных данных о
                  пользователе и остаётся одинаковым для конкретного устройства
                  вне зависимости от его местоположения, параметров или личности
                  пользователя. Идентификатор устройства не передаётся третьим
                  лицам.
                </p>
                <br />
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default PageConfidentiality;
