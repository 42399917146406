import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import NavLinkApp from "../../common/NavLinkApp";

@inject("AppStore")
@observer
class PageRko extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-20 mb56 color-text-black"}>
                С опцией РКО ваш клиент может рассчитываться за&nbsp;товары
                при&nbsp;получении любым удобным ему&nbsp;способом.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="text-p-34 mb16 color-text-black">Варианты оплаты</p>
              <ul className="rko">
                <li>
                  <img src="/img/rko/nal.svg" alt="parking" />
                  <p className={"p-small color-text-black"}>Наличные курьеру</p>
                </li>
                <li>
                  <img src="/img/rko/qr.svg" alt="compensation2" />
                  <p className={"p-small color-text-black"}>
                    Картой по QR-коду
                  </p>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content mt56">
                <blockquote className="p-text-20">
                  <div className="line-left"></div>
                  Независимо от способа оплаты, покупатель получает
                  электронный&nbsp;чек.
                </blockquote>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                Частичный выкуп
              </p>
              <p className={"text-p-20 mt16 mb56 color-text-black"}>
                Клиент может оплатить только те&nbsp;покупки, которые
                ему&nbsp;подошли. Остальное мы&nbsp;вернём вам в&nbsp;течение
                24&nbsp;часов.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mb16 mobile-mt48"}>
                Быстрые взаиморасчёты
              </p>
              <p className={"text-p-20 color-text-black"}>
                Деньги будут поступать на&nbsp;ваш расчётный счёт в&nbsp;течение
                недели с&nbsp;момента покупки, за&nbsp;вычетом комиссии
                за&nbsp;перевод 1% при&nbsp;наличном расчёте и&nbsp;2,5%
                при&nbsp;безналичной оплате.
              </p>
              <p className={"text-p-20 mt56 color-text-black"}>
                Чтобы добавить опцию РКО, нужно заключить
                <NavLinkApp className="link-a" to={"/contract"}>
                  {" "}
                  договор
                </NavLinkApp>
                .
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageRko;
