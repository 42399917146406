import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import NavLinkApp from "../../common/NavLinkApp";

@inject("AppStore")
@observer
class PageApi extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>
                Автоматизация процессов через API{" "}
              </p>
              <p className={"text-p-20 color-text-black"}>
                Интегрируйтесь с «Чекбоксом» при помощи API для&nbsp;партнёров —
                и&nbsp;избавьтесь от&nbsp;необходимости оформлять доставку
                на&nbsp;каждый заказ вручную.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                Что делает Checkbox Partner API?
              </p>
              <p className={"text-p-20 color-text-black"}>
                • Позволяет создавать срочные заказы и&nbsp;заказы
                к&nbsp;определенному времени, а&nbsp;также заказы с{" "}
                <NavLinkApp to={"/rko"}>РКО</NavLinkApp>.<br />• Даёт
                возможность получать статусы заказов в&nbsp;реальном времени.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                Как это работает?
              </p>
              <p className={"text-p-20 color-text-black"}>
                Вы интегрируете создание и&nbsp;управление заказами в&nbsp;вашу
                систему. И&nbsp;затем на&nbsp;своей стороне можете прописать
                любые нужные вам&nbsp;опции. Например виджет доставки
                для&nbsp;интернет-магазина, где&nbsp;покупатель будет
                сам&nbsp;оформлять доставку «Чекбоксом» — со&nbsp;всеми нужными
                ему&nbsp;параметрами.
                <br />
                <br />
                Посмотрите более подробную информацию об API для партнёров{" "}
                <br />
                по <a href="https://docs.partner.checkbox.ru/">ссылке</a>. А
                чтобы инженеры «Чекбокса» помогли вам с интеграцией, оставьте
                заявку.
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageApi;
