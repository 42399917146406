import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import NavLinkApp from "../common/NavLinkApp";
import { CSSTransition } from "react-transition-group";

@inject("AppStore")
@observer
class PageIndex extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.search = "";
    this.props.AppStore.setMetaPage();
  }

  render() {
    const { AppStore } = this.props;
    const { search } = AppStore;

    return (
      <Container>
        <Row>
          <Col>
            <CSSTransition
              in={Boolean(search.length)}
              timeout={500}
              classNames="it"
            >
              <div className={"search-tab"}>
                <hr className={"search-tab-line"} />
                <span className={"active"}>Спросить у робота</span>
                <span className={"no-active"}>
                  <NavLinkApp appTargetCode="human" to={"/talk"}>
                    Поговорить с человеком
                  </NavLinkApp>
                </span>
              </div>
            </CSSTransition>
          </Col>
        </Row>
        <CSSTransition
          in={Boolean(search.length)}
          timeout={500}
          classNames="ib"
        >
          <div>
            <Row>
              <Col className={"ico-container"}>
                <img
                  className={"ico-img"}
                  src="/img/icon/fixed-price.svg"
                  alt="fixed-price.svg"
                />
                <span className={"ico-text"}>
                  Фиксированная цена. Не зависит от расстояния.
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={"ico-container"}>
                <img
                  className={"ico-img"}
                  src="/img/icon/time-sensitive.svg"
                  alt="time-sensitive.svg"
                />
                <span className={"ico-text"}>
                  Доставка день в день, в часовые интервалы.
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={"ico-container"}>
                <img
                  className={"ico-img"}
                  src="/img/icon/courier.svg"
                  alt="courier.svg"
                />
                <span className={"ico-text"}>
                  Full-time курьеры на авто с кондиционерами.
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={"ico-container"}>
                <img
                  className={"ico-img"}
                  src="/img/icon/with-or-without-contract.svg"
                  alt="with-or-without-contract.svg"
                />
                <span className={"ico-text"}>
                  Работа по договору или без — как вам удобней.
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={"ico-container"}>
                <img
                  className={"ico-img"}
                  src="/img/icon/payment-options.svg"
                  alt="payment-options.svg"
                />
                <span className={"ico-text"}>Любые способы расчёта.</span>
              </Col>
            </Row>
          </div>
        </CSSTransition>
      </Container>
    );
  }
}

export default PageIndex;
