import React from "react";
import QuestionBottom from "../../common/QuestionBottom";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class PageCancel extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <p className={"text-p-34 color-text-black"}>
            Отменить или перенести доставку, а также изменить детали заказа вам
            поможет наша поддержка. <br />
            <a href="tel:88005558152">8 800 555 81 52</a>
          </p>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageCancel;
