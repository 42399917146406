import { action, observable, computed } from "mobx";
import * as firebase from "firebase/app";
import device from "current-device";
import history from "../history";
import ym from "react-yandex-metrika";
import ReactGA from "react-ga";
import "firebase/database";

ReactGA.initialize("UA-174044251-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class AppStore {
  db = {};
  searchPlaceholderDefault = "Что нужно именно вашему бизнесу?";
  @observable isSearchView = true;
  @observable DateNow = Date.now();
  @observable countForms = 0;

  @observable utm = "";

  @observable myMap = null;

  @observable meta = {
    title: "Сервис доставки в Москве",
    description:
      "Фиксированная цена. Full-time курьеры на автомобилях с кондиционерами. Простые условия.\n",
    img: "https://checkbox.ru/img/meta/meta3.png",
  };

  @observable metaJob = {
    title: "Приглашаем курьеров на личном автомобиле",
    description: "Свободный график. Стабильный доход. Компенсация ГСМ.",
    img: "https://checkbox.ru/img/meta/meta1.png",
  };

  @observable showMenu = false;

  @observable url = "/";
  @observable noFooter = false;
  @observable popupCity = false;
  @observable urlParams = [];
  @observable title = "";
  @observable localhost = null;
  @observable history;
  @observable search = "";
  @observable spbJ = false;
  @observable linkJob = "/job";
  @observable searchOne = false;
  @observable searchPlaceholder = "";
  @observable suggest = [];
  @observable suggestAll = [];
  @observable device = "table";
  @observable fontSize = 40;
  @observable selectedCity = {
    value: "Москва",
    label: "Москва",
    code: "Moscow",
  };
  cities = [{ value: "Москва", label: "Москва", code: "Moscow" }];

  @action getPageParam() {
    this.urlParams = window.location.search
      .replace("?", "")
      .split("&")
      .reduce(function (p, e) {
        var a = e.split("=");
        p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
        return p;
      }, {});
  }

  @action setPageUrlRedirect() {
    if (this.urlParams && this.urlParams["page"]) {
      this.to(this.urlParams["page"]);
    }
  }

  @action setSelectedCitySpb() {
    this.selectedCity = {
      value: "Санкт-Петербург",
      label: "Санкт-Петербург",
      code: "spb",
    };
  }

  @action setCitiesSpb() {
    this.cities = [
      { value: "Санкт-Петербург", label: "Санкт-Петербург", code: "spb" },
      { value: "Москва", label: "Москва", code: "Moscow" },
    ];
  }

  @action setCitiesNoSpb() {
    if (this.cities.length === 2) {
      this.cities = [{ value: "Москва", label: "Москва", code: "Moscow" }];
      this.selectedCity = {
        value: "Москва",
        label: "Москва",
        code: "Moscow",
      };
      this.spbJ = false;
    }
  }

  inits() {
    this.url = window.location.pathname;
    this.getPageParam();
    this.setPageUrlRedirect();
    this.popupCity = localStorage.getItem("popupCity") ? false : true;
    this.connectBd();
    this.setSuggest();
    this.setCityInit();
    this.setDevice();
    this.setFontSize();
    this.setCitySpb();
  }

  @action setCitySpb() {
    if (
      this.url === "/job-spb" ||
      this.url === "/job" ||
      this.url === "/job-spb/" ||
      this.url === "/job/"
    ) {
      this.spbJ = true;
      this.setCitiesSpb();
      if (this.url === "/job-spb" || this.url === "/job-spb/") {
        this.linkJob = "/job-spb";
        this.setSelectedCitySpb();
      }
    } else {
      this.setCitiesNoSpb();
    }
  }

  @action setCityInit() {
    let s = localStorage.getItem("selectedCity");
    if (s) {
      this.selectedCity = JSON.parse(s);
    }
  }

  /**
   * INITS METHOD
   */

  connectBd() {
    this.db = firebase.database();
  }

  @action getAllHtml() {
    this.htmlAll = [];
    this.db.ref("page-html").on("value", (snapshot) => {
      snapshot.forEach((data) => {
        this.htmlAll.push(data.val());
      });
    });
  }

  @action setSearchView(view = true) {
    this.isSearchView = view;
  }

  @action pushBd(code, ob) {
    this.db.ref(code).push(ob);
  }

  @action openMenu(e) {
    e.preventDefault();
    this.showMenu = true;
    e.stopPropagation();
  }

  @action closeMenu(e) {
    if (e !== undefined) {
      e.preventDefault();
      this.showMenu = false;
      e.stopPropagation();
    } else {
      this.showMenu = false;
    }
  }

  @action closePopupCity(e) {
    e.preventDefault();
    localStorage.setItem("popupCity", "no");
    this.popupCity = false;
    e.stopPropagation();
  }

  cityChange = (ob) => {
    this.selectedCity = ob;
    if (this.url === "/job" || this.url === "/job/") {
      if (ob.code === "spb") {
        this.to("/job-spb");
      }
    }

    if (this.url === "/job-spb" || this.url === "/job-spb/") {
      if (ob.code !== "spb") {
        this.to("/job");
      }
    }

    if (ob.code === "Moscow") {
      this.appTarget("msk"); // t1
    }
    localStorage.setItem("selectedCity", JSON.stringify(ob));
  };

  @action setSelectedCity(code) {
    this.cityChange(
      this.cities.find((item) => {
        return item.code === code;
      })
    );
    this.closeMenu();
  }

  @action setSuggest() {
    this.suggestAll = [];
    this.db.ref("suggest").on("value", (snapshot) => {
      snapshot.forEach((data) => {
        let dt = data.val();
        if (dt.active) {
          if (dt.questions !== undefined) {
            dt.questions.forEach((question) => {
              this.suggestAll.push({
                active: false,
                name: question,
                city: dt.city,
                url: dt.url,
              });
            });
          }
        }
      });
    });
  }

  @action reloadSearch() {
    window.location.href = "/";
  }

  @action setDevice() {
    this.device = device.noConflict().type;
  }

  @computed get rowSearch() {
    let n = 2;
    let s = "";
    if (this.search.length || this.searchPlaceholder.length) {
      s =
        this.searchPlaceholder.length > this.search.length
          ? this.searchPlaceholder.length
          : this.search.length;
    }
    if (s) {
      n = Math.ceil(s / 20);
    }
    return n;
  }

  @action setFontSize() {
    let fontSize = 40;
    if (this.search.length || this.searchPlaceholder.length) {
      if (document.getElementById("input-search") !== null) {
        let inputWidth = document.getElementById("input-search").offsetWidth;
        let spanInputWidth = document.getElementById("font-size-input")
          .offsetWidth;
        let spanInputWidthPlaceholder = document.getElementById(
          "font-size-input-placeholder"
        ).offsetWidth;
        let spanWidth =
          spanInputWidth > spanInputWidthPlaceholder
            ? spanInputWidth
            : spanInputWidthPlaceholder;
        fontSize = fontSize / ((spanWidth / inputWidth) * 1.1);
      }
      if (fontSize > 40) {
        fontSize = 40;
      }
      if (fontSize < 8) {
        fontSize = 8;
      }
    }
    this.fontSize = fontSize;
  }

  @action activeFocus() {
    if (this.device === "mobile") {
      document.getElementById("input-search").focus();
    } else {
      document.getElementById("textarea-search").focus();
    }
  }

  @action onResize = () => {
    this.setDevice();
    this.setFontSize();
  };

  @action searchClear() {
    this.search = "";
    this.searchPlaceholder = "";
    this.html = "";
    this.to("/");
    this.suggest = [];
  }

  @action to(url) {
    if (window.location.pathname !== url) {
      this.DateNow = Date.now();
      this.url = url;
      this.setCitySpb();
      history.push(url);
    }
  }

  @action scrollUp() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  @action toLink(e, to, name = null, close = false, appTargetCode = null) {
    if (close) {
      this.closeMenu();
    }
    e.preventDefault();
    e.stopPropagation();
    if (to === "/") {
      this.pageYes("");
    }
    if (name !== null) {
      this.setSearch(name);
      this.pushBd("query", {
        value: name,
        timestamp: Date.now(),
      });
    }
    if (appTargetCode !== null) {
      this.appTarget(appTargetCode);
    }
    this.to(to);
  }

  @action toGo(e, to) {
    e.preventDefault();
    e.stopPropagation();
    this.to(to);
  }

  @action pageYes(search = null) {
    this.suggest = [];
    this.searchPlaceholder = "";
    if (search !== null) {
      this.search = search;
    }
    this.html = "";
    this.noFooter = false;
  }

  @action isLocalhost() {
    if (window.location.host.indexOf("localhost") + 1) {
      this.localhost = false;
    } else {
      this.localhost = false;
    }
  }

  @action setTitle(title) {
    this.title = title;
  }

  @action filterSuggest() {
    let s = this.search.toUpperCase();
    this.suggest = this.suggestAll.filter((v) => {
      return v.name.toUpperCase().indexOf(s) === 0 ? true : false;
    });
  }

  @action setSearch(name) {
    this.search = name;
  }

  setSearchPlaceholder(name) {
    return this.search + name.substring(this.search.length);
  }

  @action onChangeSearch(e) {
    this.search = e.target.value;
    if (document.getElementById("font-size-input") !== null) {
      document.getElementById("font-size-input").textContent = e.target.value;
    }
    this.filterSuggest();
    if (this.search.length) {
      this.noFooter = true;
    } else {
      this.noFooter = false;
    }
    if (this.search) {
      if (this.suggest.length) {
        this.suggest.map((el) => {
          el.active = false;
          return el;
        });
        this.suggest[0].active = true;
        this.searchPlaceholder = this.setSearchPlaceholder(
          this.suggest[0].name
        );
        if (document.getElementById("font-size-input-placeholder") !== null) {
          document.getElementById(
            "font-size-input-placeholder"
          ).textContent = this.searchPlaceholder;
        }
      } else {
        this.searchPlaceholder = "";
        if (document.getElementById("font-size-input-placeholder") !== null) {
          document.getElementById("font-size-input-placeholder").textContent =
            "";
        }
        // this.to('/others');
      }
    } else {
      this.searchClear();
    }
    this.setFontSize();
  }

  @action onKeydown = (e) => {
    //Esc
    if (e.keyCode === 27) {
      this.searchClear();
    }
    //Enter
    if (e.keyCode === 13) {
      e.preventDefault();
      let elm = this.suggest.find((el) => {
        return el.active;
      });
      this.searchPlaceholder = "";
      if (document.getElementById("font-size-input-placeholder") !== null) {
        document.getElementById("font-size-input-placeholder").textContent = "";
      }
      this.pushBd("query", {
        value: this.search,
        timestamp: Date.now(),
      });
      if (elm !== undefined) {
        this.search = elm.name;
        this.to(elm.url);
      } else {
        this.to("/others");
      }
    }

    // down
    if (e.keyCode === 40) {
      e.preventDefault();
      this.suggestDown();
    }

    // up
    if (e.keyCode === 38) {
      e.preventDefault();
      this.suggestUp();
    }
    this.setFontSize();
  };

  getActiveIndexSuggest() {
    return this.suggest.findIndex((item) => {
      return item.active;
    });
  }

  @action setActiveSuggest(index, old = null) {
    if (this.search) {
      let oldIndexActive = old;
      if (old === null) {
        oldIndexActive = this.getActiveIndexSuggest();
      }

      this.suggest[oldIndexActive].active = false;
      this.suggest[index].active = true;
      this.searchPlaceholder = "";
      if (document.getElementById("font-size-input-placeholder") !== null) {
        document.getElementById("font-size-input-placeholder").textContent = "";
      }
      this.search = this.suggest[index].name;
      if (document.getElementById("font-size-input") !== null) {
        document.getElementById("font-size-input").textContent = this.search;
      }
    }
  }

  @action suggestDown() {
    let old = this.getActiveIndexSuggest();
    this.setActiveSuggest(old + 1 < this.suggest.length ? old + 1 : 0, old);
  }

  @action suggestUp() {
    let old = this.getActiveIndexSuggest();
    this.setActiveSuggest(old - 1 < 0 ? this.suggest.length - 1 : old - 1, old);
  }

  appTarget(code) {
    let ga = ReactGA.ga();
    ym("reachGoal", code);
    ga("send", "event", "itcenter", "click", code);
  }

  @action b24newForm() {
    //b24form({"id": "42", "lang": "ru", "sec": "fkcu2l", "type": "inline2"});
  }

  setMetaJob() {
    document
      .getElementById("meta-description")
      .setAttribute("content", this.metaJob.description);
    document
      .getElementById("meta-image")
      .setAttribute("content", this.metaJob.img);
    document.getElementById("title").textContent = this.metaJob.title;
  }

  setMetaPage() {
    document
      .getElementById("meta-description")
      .setAttribute("content", this.meta.description);
    document
      .getElementById("meta-image")
      .setAttribute("content", this.meta.img);
    document.getElementById("title").textContent = this.meta.title;
  }
}

export default new AppStore();
