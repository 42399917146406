import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import NavLinkApp from "../common/NavLinkApp";
import DImg from "../common/image-cmd/DImg";

@inject("AppStore")
@observer
class Page500 extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <Container className="w630">
        <div className="text-center mt-20">
          <DImg w100={false} Moscow="/img/500/500.svg" CMoscow="mb32" />
          <p className="text-p-20-28 font-weight-bold uppercase color-black mt30 mb0">
            Неопознанная серверная ошибка
          </p>
          <p className="text-p-16  mt16 color-black mb30">
            Скоро опознаем и починим, попробуйте вернуться сюда чуть позже 2
          </p>
          <NavLinkApp to={"/"} name={""} className="button text-p-18-26 line">
            Спросить о чём угодно
          </NavLinkApp>
        </div>
      </Container>
    );
  }
}

export default Page500;
