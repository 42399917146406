import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";

@inject("AppStore")
@observer
class PageInsurance extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>→ до 50000 ₽</p>
              <p className={"text-p-20 color-text-black"}>
                Материальная ответственность, прописанная в оферте.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                → до 1 млн ₽
              </p>
              <p className={"text-p-20 color-text-black"}>
                Сумма страхования по договору.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                → Ваши товары дороже 50000 ₽?
              </p>
              <p className={"text-p-20 color-text-black"}>
                Свяжитесь с менеджером, чтобы заключить договор. Просто оставьте
                ваши контакты в форме ниже.
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageInsurance;
