import React from "react";
import QuestionBottom from "../../common/QuestionBottom";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import NavLinkApp from "../../common/NavLinkApp";

@inject("AppStore")
@observer
class PageCars extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <p className={"text-p-22 color-text-black"}>
            Мы доставляем{" "}
            <NavLinkApp className="link-a" to={"/undeliverable"}>
              посылки
            </NavLinkApp>{" "}
            на&nbsp;легковых автомобилях с&nbsp;кондиционерами.
          </p>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageCars;
