import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import NavLinkApp from "../common/NavLinkApp";
import DImg from "../common/image-cmd/DImg";

@inject("AppStore")
@observer
class Page404 extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <Container className="w630">
        <div className="text-center mt-20">
          <DImg
            w100={false}
            Moscow="/img/404/404_2.svg"
            MoscowM="/img/404/404_2m.svg"
            CMoscow="mb32"
            CMoscowM="mb32"
          />
          <p className="text-p-20-28 font-weight-bold uppercase color-black mt30 mb0">
            Даже на краю вселенной
          </p>
          <p className="text-p-20-28 font-weight-bold uppercase color-black ">
            мы не можем найти эту страницу
          </p>
          <p className="text-p-16 mt16 mb30">
            Возможно, её больше нет или никогда не было
          </p>
          <NavLinkApp to={"/"} name={""} className="button text-p-18-26 line">
            Спросить о чём угодно
          </NavLinkApp>
        </div>
      </Container>
    );
  }
}

export default Page404;
