import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";

@inject("AppStore")
@observer
class PageUndeliverable extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>
                Что можно отправить с «Чекбоксом»?
              </p>
              <p className={"text-p-20 color-text-black mb56"}>
                Практически что-угодно. Особенно если это не превышает
                30&nbsp;кг и&nbsp;100*70*50&nbsp;см.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content mb60">
                <blockquote className="p-text-20">
                  <div className="line-left"></div>
                  Например: мелкую бытовую технику, документы, цветы,
                  кондитерские изделия, товары для дома, заранее собранные
                  продукты из магазина, книги, сувениры, одежду с примеркой,
                  игрушки для детей и взрослых.
                </blockquote>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content">
                <p className="text-p-34 color-text-black mb12">
                  <img
                    className="mb7"
                    src="/img/undeliverable/Block 1.svg"
                    alt=""
                  />{" "}
                  Что мы не доставляем:{" "}
                </p>
                <ul className="p-text-20">
                  <li>— Горячую еду из ресторанов</li>
                  <li>— Деньги и драгоценности</li>
                  <li>— Животных</li>
                  <li>— Любое оружие и боеприпасы</li>
                  <li>
                    — Всё, что может загореться, взорваться, быть токсичным или
                    опасным
                  </li>
                  <li>— Медицинские и наркотические препараты</li>
                  <li>— Грязные и / или неприятно пахнущие посылки</li>
                  <li>
                    — Заказы, упаковка которых может повредить другие грузы.
                  </li>
                  <li>
                    — Посылки на вокзал, которые нужно передать водителю для
                    последующей транспортировки товара.
                  </li>
                  <li>
                    — Не закреплённые в коробке десерты и торты выше одного
                    яруса.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageUndeliverable;
