import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import NavLinkApp from "../../common/NavLinkApp";

@inject("AppStore")
@observer
class PageCash extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>→ 10 000 ₽</p>
              <p className={"text-p-20 color-text-black"}>
                максимальная сумма выкупа.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black mt56 mobile-mt48"}>
                Как это работает?
              </p>
              <p className={"text-p-20 color-text-black"}>
                Курьер покупает у вас посылку за наличные, как если бы клиент
                сам к&nbsp;вам приехал и купил. Больше никаких предоплат:
                покупатель расплачивается уже с курьером — без рисков.
                <br />
                <br />
                Это бесплатная опция, доступная после заключения
                <NavLinkApp to={"/contract"}> договора</NavLinkApp>.
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PageCash;
