import React from "react";

class IcoLeftText2 extends React.Component {
  render() {
    let { title, text, ico, classNameText, classNameTitle } = this.props;
    if (!classNameText) {
      classNameText = "";
    }
    if (!classNameTitle) {
      classNameTitle = "";
    }
    return (
      <React.Fragment>
        <p className={"h3-m color-text-black d-table mb13 " + classNameTitle}>
          <img className="mr16" src={ico} alt="10k3" />
          <span className="d-table-cell">{title}</span>
        </p>
        <p className={"text-20-36-400 color-text-black " + classNameText}>
          {text}
        </p>
      </React.Fragment>
    );
  }
}

export default IcoLeftText2;
