import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import DImg from "../common/image-cmd/DImg";
import svg1 from "../../src/img/refer/1.svg";
import svg2 from "../../src/img/refer/2.svg";
import svg3 from "../../src/img/refer/3.svg";

import DownloadDocx from "../common/DownloadDocx";

@inject("AppStore")
@observer
class PageRefer extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
    this.showScript();
  }

  showScript2() {
    this.instance1.innerHTML = "";
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.id = "bx24_form_inline1";
    s.innerHTML =
      "        (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;\n" +
      "                (w[b].forms=w[b].forms||[]).push(arguments[0])};\n" +
      "                if(w[b]['forms']) return;\n" +
      "                var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());\n" +
      "                var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);\n" +
      "        })(window,document,'https://checkbox.bitrix24.ru/bitrix/js/crm/form_loader.js','b24form');\n" +
      "\n";

    s.innerHTML +=
      'b24form({"id":"60","lang":"ru","sec":"mdtyt3","type":"inline1"});\n';
    this.instance1.append(s);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.showScript();
  }

  showScript() {
    const { AppStore } = this.props;
    if (document.getElementById("show1script")) {
      if (window.b24form) {
        window.b24form.forms = [];
      }
      this.showScript2();
      if (window.Bitrix24FormLoader && AppStore.countForms) {
        window.Bitrix24FormLoader.init();
      }
      AppStore.countForms++;
    }
  }

  render() {
    return (
      <Container className="w768 mt120">
        <Row>
          <Col>
            <p className={"text-p-34 mb50 color-text-blue"}>
              Скидка на доставку для друзей и коллег наших партнёров
            </p>
            <p className={"text-p-20 color-text-black"}>
              У нас вежливые курьеры на легковых автомобилях говорят на одном
              языке с вашими клиентами.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={"text-p-20-32-700 mt60"}>Фиксированная цена</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={"text-p-19-25-700 mt12"}>→ 400 ₽</p>
            <p className={"text-p-19-25-400 mt12"}>в пределах МКАД</p>
          </Col>
          <Col>
            <p className={"text-p-19-25-700 "}>→ 700 ₽</p>
            <p className={"text-p-19-25-400 "}>до 10 км от МКАД</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={"text-p-16-28-400 mt12"}>
              По договору возможна индивидуальная цена под ваши объёмы, вес и
              географию доставки.
            </p>
          </Col>
        </Row>
        <Row className={"mt60"}>
          <Col>
            <DImg Moscow={svg1} />
            <p className={"text-p-16-28-400"}>
              Доставка день в день, в часовые интервалы
            </p>
          </Col>
          <Col>
            <DImg Moscow={svg2} />
            <p className={"text-p-16-28-400"}>
              Все способы расчёта за доставку и товар
            </p>
          </Col>
          <Col>
            <DImg Moscow={svg3} />
            <p className={"text-p-16-28-400"}>Термосумки у каждого курьера</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className={"text-p-34 mb50 color-text-blue mt120"}>
              Как получить скидку на доставку?
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul className={"ul-refer"}>
              <li>1). Заполните форму на заключение договора.</li>
              <li>
                2). В последнем поле «Порекомендовал друг? Укажите его номер
                телефона!» напишите номер коллеги, который нас порекомендовал.
                Номер указан в ссылке, по которой вы перешли на эту страницу.
              </li>
              <li>3). Ожидайте звонка от менеджера.</li>
              <li>
                4). Заключите договор и закажите доставку до 1 апреля — и
                получите скидку 10% на весь первый месяц сотрудничества.
              </li>
              <li>
                5). Оплачивайте все доставки по договору раз в месяц
                (постоплата).
              </li>
            </ul>
          </Col>
        </Row>
        <div
          id="show1script"
          className={"mt50"}
          ref={(el) => (this.instance1 = el)}
        />
        <DownloadDocx />
      </Container>
    );
  }
}

export default PageRefer;
