import { inject, observer } from "mobx-react";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import QuestionBottom from "../../common/QuestionBottom";
import IcoLeftText2 from "../../common/text/IcoLeftText2";
import DImg from "../../common/image-cmd/DImg";
import ImgQuestion from "../../img/suggest/perishable/question.svg";

@inject("AppStore")
@observer
class PagePerishable extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="w768 mt120">
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>
                Опция «Скоропортящийся товар»{" "}
                <span className="text-color-blue">+ 200 ₽</span>
              </p>
              <p className={"text-p-20 color-text-black mb56"}>
                → Доставляем сразу, как забрали, на расстояния в пределах
                25&nbsp;км.
                <br />
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <IcoLeftText2
                title="Какие товары мы рекомендуем доставлять с опцией «Скоропортящийся товар»:"
                text={
                  <div className="content">
                    <ul className="p-text-20">
                      <li>— Живые морепродукты (устрицы, раки, крабы)</li>
                      <li>— Комнатные растения</li>
                      <li>— Букеты, в том числе съедобные</li>
                      <li>— Торты и другие кондитерские изделия</li>
                      <li>— Скоропортящиеся продукты питания</li>
                      <li>— Заморозки</li>
                    </ul>
                  </div>
                }
                ico={ImgQuestion}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-p-34 color-text-black"}>
                Мы соблюдаем температурный режим
              </p>
              <p className={"text-p-20 color-text-black mb56"}>
                У всех курьеров «Чекбокса» всегда с собой термосумки. Холодное
                останется холодным, а горячее — горячим.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <IcoLeftText2
                title="Доставка тортов"
                text="Сумки надёжно фиксируются в машине, чтобы торт попал
                на&nbsp;праздничный стол в лучшем виде."
                ico="/img/temperature/cake-svgrepo-com 1.svg"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content mb56">
                <blockquote className="p-text-20">
                  <div className="line-left"></div>К доставке принимаются только
                  одноярусные торты.
                </blockquote>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <IcoLeftText2
                title="Доставка цветов"
                text=" Букеты комфортно себя чувствуют в сумках и доезжают свежими
                и&nbsp;красивыми."
                ico="/img/temperature/Flower 1.svg"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={"text-20-36-400 mt56 color-text-black"}>
                Если ваша посылка должна перевозиться именно в термосумке —
                предупредите нас, пожалуйста, при оформлении заказа. Так
                к&nbsp;вам точно приедет курьер со свободной сумкой.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <DImg
                  Moscow="/img/temperature/teremo.svg"
                  CMoscow="mt40 filter"
                  MoscowM="/img/temperature/image 8m.svg"
                  CMoscowM="mt16 filter"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <QuestionBottom />
      </React.Fragment>
    );
  }
}

export default PagePerishable;
