import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class PagePrivacyPolicy extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className={"w768"}>
          <div className="text">
            <h1>ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>

            <h2>1. Общие положения</h2>
            <p>
              1.1. Политика в отношении обработки персональных данных (далее —
              Политика) направлена на защиту прав и свобод физических лиц,
              персональные данные которых обрабатывает АО «Самокарт» (далее —
              Оператор).
              <br />
              1.2. Политика разработана в соответствии с п. 2 ч. 1 ст. 18.1
              Федерального закона от 27 июля 2006 г. № 152-ФЗ «О персональных
              данных» (далее — ФЗ «О персональных данных»).
              <br />
              1.3. Политика содержит сведения, подлежащие раскрытию в
              соответствии с ч. 1 ст. 14 ФЗ «О персональных данных», и является
              общедоступным документом.
              <br />
            </p>
            <h2>2. Сведения об операторе</h2>
            <p>
              2.1. Оператор ведет свою деятельность по адресу 350051, город
              Краснодар, улица Стахановская, дом 15, корпус 3, комната 2.
              <br />
            </p>
            <h2>3. Сведения об обработке персональных данных</h2>
            <p>
              3.1. Оператор обрабатывает персональные данные на законной и
              справедливой основе для выполнения возложенных законодательством
              функций, полномочий и обязанностей, осуществления прав и законных
              интересов Оператора, работников Оператора и третьих лиц.
              <br />
              3.2. Оператор получает персональные данные непосредственно у
              субъектов персональных данных (далее – ПДн).
              <br />
              3.3. Оператор обрабатывает персональные данные автоматизированным
              и неавтоматизированным способами, с использованием средств
              вычислительной техники и без использования таких средств.
              <br />
              3.4. Действия по обработке персональных данных включают сбор,
              запись, систематизацию, накопление, хранение, уточнение
              (обновление, изменение), извлечение, использование, передачу
              (распространение, предоставление, доступ), обезличивание,
              блокирование, удаление и уничтожение.
              <br />
            </p>
            <h2>4. Обработка персональных данных клиентов</h2>
            <p>
              4.1. Оператор обрабатывает персональные данные заказчиков и
              курьеров в рамкахправоотношений с Оператором, урегулированных
              частью второй Гражданского Кодекса Российской Федерации от 26
              января 1996 г. № 14-ФЗ, (далее — клиентов).
              <br />
              4.2. Оператор обрабатывает персональные данные клиентов в целях
              соблюдения норм законодательства РФ, а также с целью:
              <br />
              <br />
              <ul>
                <li>прием обращений и заявок от субъекта ПДн;</li>
                <li>информировать о специальных акциях и предложениях;</li>
                <li>заключение и исполнение условий договора.</li>
              </ul>
              4.3. Оператор обрабатывает персональные данные заказчиков и
              курьеров с их согласия, предоставляемого ими самими и/или их
              законными представителями путем совершения конклюдентных действий
              на настоящем интернет-сайте, в том числе, но не ограничиваясь,
              оформлением заказа, регистрацией в личном кабинете, в соответствии
              с настоящей Политикой.
              <br />
              4.4. Оператор обрабатывает персональные данные клиентов не дольше,
              чем того требуют цели обработки персональных данных, если иное не
              предусмотрено требованиями законодательства РФ.
              <br />
              4.5. Оператор может обрабатывать следующие персональные данные:
              <br />
              <br />
              <ul>
                <li> Фамилия, имя, отчество;</li>
                <li>Адрес;</li>
                <li>Номер контактного телефона;</li>
                <li>Адрес электронной почты.</li>
              </ul>
              4.6. Не осуществляется обработка специальных категорий
              персональных данных:
              <br />
              4.6.1. касающихся расовой, национальной принадлежности;
              <br />
              4.6.2. политических взглядов, религиозных или философских
              убеждений;
              <br />
              4.6.3. здоровья и интимной жизни.
              <br />
            </p>
            <h2>5. Сведения об обеспечении безопасности персональных данных</h2>
            <p>
              5.1. Оператор при обработке персональных данных принимает
              необходимые правовые, организационные и технические меры или
              обеспечивает их принятие для защиты персональных данных от
              неправомерного или случайного доступа к ним, уничтожения,
              изменения, блокирования, копирования, предоставления,
              распространения персональных данных, а также от иных неправомерных
              действий в отношении персональных данных.
              <br />
              5.2. Меры по обеспечению безопасности персональных данных при их
              обработке, применяемые Оператором, планируются и реализуются в
              целях обеспечения соответствия требованиям, приведенным в статье
              19 ФЗ-152 «О персональных данных».
              <br />
              5.3. В соответствии со статьей 18.1 ФЗ-152 Оператор самостоятельно
              определяет состав и перечень мер, необходимых и достаточных для
              обеспечения выполнения требований законодательства. Оператор в
              частности принял следующие меры:
              <br />
              <br />
              <ul>
                <li>назначен ответственный за организацию обработки ПДн;</li>
                <li>
                  разработаны и внедрены локальные акты по вопросам обработки
                  ПДн, а также локальные акты, устанавливающие процедуры,
                  направленные на предотвращение ивыявление нарушений
                  установленных процедур по обработке ПДн и устранение
                  последствий таких нарушений;
                </li>
                <li>
                  применяются правовые, организационные и технические меры по
                  обеспечению безопасности ПДн в соответствии со статьей 19
                  ФЗ-152;
                </li>
                <li>
                  осуществляется внутренний контроль соответствия обработки ПДн
                  ФЗ-152 и принятым в соответствии с ним нормативным правовым
                  актам, требованиям к защите ПДн, политике Оператора в
                  отношении обработки ПДн, локальным актам Оператора;
                </li>
                <li>
                  проводится оценка вреда, который может быть причинен субъектам
                  персональных данных в случае нарушения ФЗ-152, соотношение
                  указанного вреда и принимаемых оператором мер, направленных на
                  обеспечение выполнения обязанностей, предусмотренных ФЗ-152;
                </li>
                <li>
                  работники Оператора, непосредственно осуществляющие обработку
                  ПДн, ознакомлены с положениями законодательства Российской
                  Федерации о ПДн, в том числе требованиями к защите ПДн,
                  документами, определяющими политику Оператора в отношении
                  обработки ПДн, локальными актами по вопросам обработки ПДн;
                </li>
                <li>
                  В дополнение к требованиям 152-ФЗ «О персональных данных», у
                  Оператора осуществляется комплекс мероприятий, направленных на
                  защиту информации о клиентах, работниках и контрагентах.
                </li>
              </ul>
            </p>
            <h2>6. Права субъектов персональных данных</h2>
            <p>
              6.1. Субъект персональных данных имеет право:
              <br />
              <br />
              <ul>
                <li>
                  на получение персональных данных, относящихся к данному
                  субъекту, и информации, касающейся их обработки;
                </li>
                <li>
                  на уточнение, блокирование или уничтожение его персональных
                  данных в случае, если они являются неполными, устаревшими,
                  неточными, незаконно полученными или не являются необходимыми
                  для заявленной цели обработки;
                </li>
                <li>
                  на отзыв данного им согласия на обработку персональных данных;
                </li>
                <li>
                  на защиту своих прав и законных интересов, в том числе на
                  возмещение убытков и компенсацию морального вреда в судебном
                  порядке;
                </li>
                <li>
                  на обжалование действий или бездействия Оператора в
                  уполномоченный орган по защите прав субъектов персональных
                  данных или в судебном порядке.
                </li>
              </ul>
              6.2. Для реализации своих прав и законных интересов субъекты
              персональных данных имеют право обратиться к Оператору либо
              направить запрос лично или с помощью представителя. Запрос должен
              содержать сведения, указанные в ч. 3 ст. 14 ФЗ «О персональных
              данных».
              <br />
            </p>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default PagePrivacyPolicy;
