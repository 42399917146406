import React from "react";
import QuestionBottom from "../../common/QuestionBottom";
import { Col, Container, Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";
import NavLinkApp from "../../common/NavLinkApp";

@inject("AppStore")
@observer
class PageTalk extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.searchPlaceholder = "";
    this.props.AppStore.search = " ";
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col>
              <div className={"search-tab talk block-talk"}>
                <hr className={"search-tab-line"} />
                <span className={"no-active"}>
                  <NavLinkApp appTargetCode="robot" to={"/"}>
                    Спросить у робота
                  </NavLinkApp>
                </span>
                <span className={"active"}>Поговорить с человеком</span>
              </div>
            </Col>
          </Row>
        </Container>
        <QuestionBottom question={false} variant={2} mt={"mt80"} />
      </React.Fragment>
    );
  }
}

export default PageTalk;
