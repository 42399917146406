import React from "react";
import QuestionBottom from "../../common/QuestionBottom";
import { Col, Container, Row } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class PageOthers extends React.Component {
  componentDidMount() {
    this.props.AppStore.pageYes();
    this.props.AppStore.searchPlaceholder = "";
    this.props.AppStore.setSearchView();
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className="mt24">
          <Row>
            <Col>
              <img
                className={"img-robot"}
                src="/img/icon/manager.svg"
                alt="manager"
              />
              <p className="manager text-p-24 color-text-black">
                Ответ на этот вопрос знает только менеджер
              </p>
            </Col>
          </Row>
        </Container>
        <QuestionBottom variant={2} />
      </React.Fragment>
    );
  }
}

export default PageOthers;
