import React from "react";
import { Container } from "react-bootstrap";
import { inject, observer } from "mobx-react";

@inject("AppStore")
@observer
class PageShippingVoronezh extends React.Component {
  componentDidMount() {
    this.props.AppStore.setSearchView(false);
    this.props.AppStore.setMetaPage();
  }

  render() {
    return (
      <React.Fragment>
        <Container className={"w768"}>
          <div className="text">
            <h1>ОФЕРТА (ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ)</h1>
            <p>НА ОКАЗАНИЕ УСЛУГ ПО&nbsp; ДОСТАВКЕ</p>
            <p>
              Настоящее пользовательское соглашение (далее - «Оферта»), в
              соответствии с положениями ст. 437 ГК РФ является публичной
              офертой Акционерного общества «Самокарт», далее - «Чекбокс», и
              адресовано любому физическому&nbsp; дееспособному лицу,
              применяющему специальный налоговый режим «
              <a href="http://www.consultant.ru/document/cons_doc_LAW_311977/">
                Налог на профессиональный доход
              </a>
              »
              <a href="http://www.consultant.ru/document/cons_doc_LAW_311977/">
                {" "}
              </a>
              в соответствии с{" "}
              <a href="http://www.consultant.ru/document/cons_doc_LAW_311977/">
                Федеральным законом от 27.11.2018 N 422-ФЗ
              </a>
              , далее - «Курьер». Безусловным акцептом Курьером настоящей Оферты
              является Регистрация в Интерфейсе Чекбокса в качестве лица,
              желающего оказывать услуги по доставке с помощью Интерфейса
              Чекбокс.
            </p>
            <p>
              <br />
            </p>
            <h2>1. &nbsp; ОСНОВНЫЕ ТЕРМИНЫ&nbsp;</h2>
            <p>
              1.1. <b>Оферта</b> – настоящее соглашение, заключаемое между
              Курьером и Чекбоксом на условиях, указанных ниже.&nbsp;
            </p>
            <p>
              1.2. <b>Клиент</b> – полностью дееспособное физическое лицо,
              разместившее заявку на доставку разрешенных к перевозке предметов
              через Сайт или горячую линию Партнера.
            </p>
            <p>
              1.3. <b>Чекбокс</b> – Акционерное общество «Самокарт» (ИНН
              2310212217, юридический адрес: Россия, г. Краснодар, ул.
              Стахановская, 15, корп. 3, комната 2), являющееся владельцем,
              оператором и администратором интерфейса (API) checkbox.ru для
              распределения и учета заказов на доставку.
            </p>
            <p>
              1.4. <b>Курьер</b> – любое дееспособное физическое лицо,
              применяющее специальный налоговый режим «
              <a href="http://www.consultant.ru/document/cons_doc_LAW_311977/">
                Налог на профессиональный доход
              </a>
              »
              <a href="http://www.consultant.ru/document/cons_doc_LAW_311977/">
                {" "}
              </a>
              в соответствии с{" "}
              <a href="http://www.consultant.ru/document/cons_doc_LAW_311977/">
                Федеральным законом от 27.11.2018 N 422-ФЗ
              </a>{" "}
              и желающее оказывать услуги по доставке с помощью Интерфейса.
            </p>
            <p>
              1.5. <b>&nbsp; Партнер&nbsp;</b> (отправитель) - юридическое лицо,
              которое заключило либо намеревается заключить с Клиентом договор
              купли-продажи товара, содержащегося в Отправлении, владелец сайта
              или горячей линии, через которую Клиенты совершают Заказы и (или)
              оплачивают их.
            </p>
            <p>
              1.6. <b>Интерфейс (API)</b> – web или мобильное приложение
              Чекбокса (информационная система), позволяющее Клиенту заполнить
              все данные Заказа, необходимые для его осуществления (адрес
              отправления и получения, время доставки, стоимость, и т.д.),
              сформировать и разместить Заявку, а Курьеру - получить ее; а также
              совокупность информации о состоянии и движении Заявок, о новых
              заявках, о тарифе Курьера, совокупной стоимости выполненных им
              Заявок&nbsp; и размере вознаграждения Курьера, состояния лицевого
              счета, и иной информации и средств ее обработки, используемой
              сторонами для обмена документами и информацией в электронном виде.
              Доступ в Интерфейс осуществляется после регистрации через личный
              кабинет (телеграм-бот).
            </p>
            <p>
              1.7. <b>Лицевой счет</b> - способ виртуальных расчетов Сторон
              через Интерфейс, позволяющий Курьеру ежедневно открывать сессии
              для работы через Интерфейс, в том числе для приема новых Заявок,
              расчета стоимости выполненных Заявок,&nbsp; и отслеживания
              задолженности Сторон.&nbsp;
            </p>
            <p>
              1.8. <b>Индивидуальный тариф</b> - индивидуальные финансовые
              условия, по которым рассчитывается стоимость&nbsp; выполнения
              Заявок на доставку Курьером, устанавливается порог отключения,
              порог включения,&nbsp; неснижаемый остаток. Условия
              индивидуального тарифа становятся доступны Курьеру после
              регистрации в Интерфейсе.&nbsp;
            </p>
            <p>
              1.9. <b>Заявка</b> - документ, составленный Партнером в
              электронном виде, который передается по согласованному Сторонами
              протоколу взаимодействия через Интерфейс;&nbsp; и содержит в себе
              информацию о Заказах и адресах доставки в пределах Зоны
              доставки.&nbsp;
            </p>
            <p>
              1.10. <b>Заказ (отправление)</b> - указанный в заявке предмет или
              товар (разрешенный к перевозке на территории РФ), в отношении
              которого&nbsp; Партнер дает Курьеру поручение доставить по
              указанному Клиентом адресу.&nbsp;
            </p>
            <p>
              1.11. <b>Участие в расчетах за товар</b> - посредническая услуга
              Курьера, выполняемая от имени, по поручению и за счет Партнера, по
              участию в в наличных или безналичных расчетах за товары в момент
              доставки и передачи Заказа Клиенту (получателю, названному
              Клиентом).
            </p>
            <p>
              1.12. <b>Адрес отправления</b> – любой адрес в г. Воронеж, по
              которому Курьер производит прием отправлений у лица, названного
              Партнером отправителем, для последующей доставки.&nbsp;
            </p>
            <p>
              1.13. <b>Адрес доставки</b> - любой адрес в г. Воронеж, по
              которому Курьер производит вручение отправлений лицу, названному
              Партнером получателем отправления.
            </p>
            <p>
              1.14. <b>Зона доставки</b> - г. Воронеж&nbsp;
            </p>
            <p>
              (
              <a href="https://yandex.ru/map-widget/v1/?um=constructor%3A3d412ed4ed27fb47c5d9aecaa9c0ca7b07bdd3bfd56a4d6e039cfa433ae8716a&source=constructor">
                Карта
              </a>
              )
            </p>
            <p>
              1.15. <b>Простая электронная подпись (ПЭП)</b> - электронная
              подпись (информация в электронном виде), которая посредством
              использования кодов подтверждения, паролей, sms или иных средств
              подтверждает факт формирования электронной подписи (отправления
              сообщения) определенным лицом (Партнером, Курьером,
              Чекбоксом).&nbsp;
            </p>
            <p>
              1.16. <b>Уведомление</b> – электронное сообщение, направляемое
              Сторонами через Интерфейс, а также при возможности по электронной
              почте.
            </p>
            <p>
              <br />
            </p>
            <h2>2. &nbsp; &nbsp; ПРЕДМЕТ&nbsp;</h2>
            <p>
              2.1. Предмет.&nbsp; Чекбокс предлагает любому дееспособному
              физическое лицу, обладающим признаками, указанными в&nbsp; п.1.4.
              настоящей Оферты, используя Интерфейс, за вознаграждение оказывать
              Чекбоксу (по поручению Партнера)&nbsp; совокупность услуг,
              включая: (1) услуги по доставке отправлений;&nbsp; (2)
              посреднические услуги по участию в расчетах за доставляемые
              отправления, в момент доставки отправлений, и (3) оказание иных
              услуг в соответствии с Офертой; а Чекбокс обязуется принять
              указанные услуги и оплачивать их через Интерфейс.&nbsp;
            </p>
            <p>
              2.2. Услуги по доставке: доставка указанного в Заявке предмета,
              товара (Отправления), разрешенного к перевозке законодательством
              РФ, по указанному адресу в пределах зоны доставки по адресу
              доставки.
            </p>
            <p>
              2.3.&nbsp; Посреднические (агентские) услуги: участие в наличных и
              безналичных расчетах за товары, указанные в Заявке, в момент их
              доставки Клиентам. Курьер либо Чекбокс не являются платежными
              агентами, а также агентами по продаже товаров, не выполняют их
              функции. Договор купли-продажи заключается напрямую между
              Партнером и Клиентом при нажатии на Сайте кнопки “оформить заказ”,
              либо иной кнопки с соответствующим функционалом.&nbsp;
            </p>
            <p>
              2.4. Иные услуги:&nbsp; участие в маркетинговых и иных акциях
              Чекбокса, информация о которых становится доступной в личном
              кабинете (телеграм-боте), в случае их проведения.
            </p>
            <p>
              2.5. Квалификация Договора Оферты. К правоотношениям между
              Чекбоксом и Курьером применяются положения Гражданского кодекса
              Российской Федерации об оферте (ГК РФ ч.1), о возмездном оказании
              услуг (глава 39), об агентировании (глава 52) и иные правовые
              акты, принятые в соответствии с ними. В соответствии с подпунктом
              1 ч. 2 ст. 1 Федерального закона 103-ФЗ от 03.06.2009 г. положения
              вышеуказанного закона не применяются к проведению расчетов за
              доставленные Заказы&nbsp; и сопутствующие услуги в рамках
              настоящего Договора,&nbsp; Отношения Курьера и Чекбокса в части
              исполнения поручения, указанного в пункте 2.3. настоящего
              Договора, регулируются исключительно&nbsp; главой 52 Гражданского
              кодекса Российской Федерации.
            </p>
            <p>
              2.6. Чекбокс не предлагает к исполнению Курьерам заказы по
              доставке крупногабаритных грузов, упаковке товара, хранению
              товара, содействию в оформлении сопроводительных документов, а
              также доставке отправлений за пределы Зоны доставки.&nbsp;
            </p>
            <p>
              2.7. Собственность на Заказ. Заказ, переданный Курьеру, остается в
              собственности Отправителя до доставки товара по адресу получения
              (в момент исполнения обязательства по передаче товара указанному
              получателю за исключением случаев отказа получателя от Заказа в
              момент передачи). При этом Курьер несет риск случайной гибели
              и/или повреждения Заказа с момента его получения по адресу
              отправления и до момента передачи товара по адресу доставки, за
              исключением рисков, связанных с ненадлежащей упаковкой
              отправления.&nbsp;
            </p>
            <p>
              <br />
            </p>
            <h2>3.&nbsp; &nbsp; &nbsp; ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
            <b>3.1.&nbsp; &nbsp; Курьер:</b>
            <p>
              3.1.1. Во время действия Оферты Курьер обязуется осуществлять
              уплату всех необходимых налогов в соответствии с законодательством
              Российской Федерации, в том числе в соответствии с{" "}
              <a href="http://www.consultant.ru/document/cons_doc_LAW_311977/">
                Федеральным законом от 27.11.2018 N 422-ФЗ
              </a>
              .
            </p>
            <p>
              3.1.2. Регистрация в Интерфейсе, создание личного кабинета в нем
              является акцептом Оферты Чекбокса. В этом случае Стороны не
              оформляют и не подписывают договор на бумажном носителе как
              отдельный документ, необходимая форма договора считается
              соблюденной (п.4.ст.434 ГК РФ).
            </p>
            <p>
              3.1.3.&nbsp; Акцепт Оферты означает, что Курьер согласен со всеми
              положениями настоящей Оферты, что Курьер производит оказание услуг
              по доставке и, в случае необходимости, по участию в расчетах за
              товар&nbsp; в соответствии с настоящей Офертой, а также дает свое
              разрешение на обработку своих персональных данных для оказания
              услуг в соответствии с принятыми Заявками.
            </p>
            <p>
              3.1.4. Если Курьер не согласен с каким-либо пунктом настоящей
              Оферты, он не должен принимать Заявки в Интерфейсе и выполнять их.
            </p>
            <p>
              3.1.5. Курьер обязан использовать собственный смартфон с
              установленным на него Интерфейсом.
            </p>
            <p>
              3.1.6. Курьер не вправе привлекать к выполнению Заявок третьих
              лиц.&nbsp;
            </p>
            <p>
              3.1.7. Курьер гарантирует возмещение в полном объеме стоимости
              Заказов (Отправлений), в случае их утраты или повреждения, в
              течение 3 (трех) календарных дней с момента получения
              соответствующего требования.&nbsp;
            </p>
            <p>
              3.1.8. Курьер обязуется не раскрывать предоставленную информацию о
              Клиентах и использовать ее только в целях, определенных настоящей
              Офертой и условиями согласия на обработку персональных данных,
              полученного Чекбоксом при оформлении Клиентом Заявки.
            </p>
            <p>
              3.1.9. Курьер обязуется незамедлительно извещать Чекбокс об
              изменении паспортных данных, банковских реквизитов и налогового
              режима не позднее 3 (Трех) дней с момента таких изменений.
            </p>
            <p>
              3.1.10. Курьер, присоединяясь к настоящей Оферте, соглашается на
              электронный документооборот и электронное взаимодействие. Номер
              мобильного телефона, указанного при регистрации в Интерфейсе,
              признаются электронной информацией, приравненной к простой
              электронной подписи.
            </p>
            <p>
              3.1.11. Курьер не несет ответственности за качество доставляемого
              отправления, в случае наличия претензий или вопросов к качеству
              предметов в Заказе, Клиент (получатель) должен обращаться
              непосредственно к отправителю Заказа (Партнеру).&nbsp;
            </p>
            <p>
              3.1.12. Курьер обязуется соблюдать финансовые условия,
              предусмотренные индивидуальным тарифом, указанным в Интерфейсе в
              личном кабинете Курьера.
            </p>
            <p>
              <br />
            </p>
            <b>3.2. Порядок оказания услуг по доставке:</b>
            <p>
              3.2.1. Курьер обязуется забрать Отправление у отправителя и
              доставить Отправление получателю в соответствии с информацией,
              указанной в Заявке.
            </p>
            <p>
              3.2.2. В случае если доставку Отправления невозможно выполнить по
              причине отсутствия получателя в месте и время, указанное в Заявке,
              либо по любой другой не зависящей от Чекбокса и Курьера причине,
              Курьер обязуется уведомить об этом Партнера и вернуть обратно
              Отправление, при этом доставка Отправления получателю считается
              выполненной и подлежит оплате.
            </p>
            <p>
              3.2.3. Курьер вправе осмотреть Отправление и отказать Партнеру в
              оказании услуги доставки Отправления, если оно содержит:
            </p>
            <ul>
              <li>
                <p>иностранную валюту и денежные знаки РФ;</p>
              </li>
              <li>
                <p>живые или мертвые животные и растения;</p>
              </li>
              <li>
                <p>
                  огнестрельное, пневматическое, газовое или холодное оружие и
                  его части, боеприпасы, фейерверки, сигнальные ракеты и
                  патроны;
                </p>
              </li>
              <li>
                <p>
                  наркотические средства, психотропные, сильнодействующие,
                  токсичные, радиоактивные, взрывчатые, ядовитые, едкие,
                  легковоспламеняющиеся и другие опасные вещества, в том числе
                  находящиеся под давлением;
                </p>
              </li>
              <li>
                <p>
                  продукты с неприятным запахом, предметы и вещества, которые по
                  своему характеру или из-за упаковки могут представлять
                  опасность для людей, загрязнять или портить (повреждать)
                  другие грузы, окружающих людей или предметы,
                </p>
              </li>
              <li>
                <p>
                  изделия из золота, серебра, других драгоценных и
                  полудрагоценных металлов и камней;
                </p>
              </li>
              <li>
                <p>любые отправления, превышающие параметры 100х100х100 см;</p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  любые отправления стоимостью более 100 000 (сто тысяч рублей)
                  рублей;
                </p>
              </li>
            </ul>
            <ul>
              <li>
                <p>
                  любые отправления за пределы Зоны доставки.
                  <br />
                  <br />
                </p>
              </li>
            </ul>
            <p>
              3.2.4. Курьер вправе отказаться от доставки Отправления, если оно
              не соответствует описанию в Заявке или если фактические условия
              оказания услуг по доставке, о которых Курьеру сообщил отправитель
              либо Клиент (в зависимости от ситуации), не соответствуют другим
              условиям Заявки.
            </p>
            <p>
              3.2.5. Доставка осуществляется по городу Воронеж во время,
              согласованное в Заявках. Чекбокс и Партнер соглашаются с тем, что
              время доставки может меняться по независящим от Курьера причинам -
              поломка автомобиля, ДТП, пробки на дорогах и т.п. В этом случае
              Курьер и Партнер согласовывают новое время доставки.
            </p>
            <p>
              3.2.6. Моментом исполнения обязательств Курьера по каждой из
              Заявок является отметка Курьера в Интерфейсе при получении
              Отправления от отправителя, и отметка о передаче Отправления
              получателю.
            </p>
            <p>
              <br />
            </p>
            <b>3.3. &nbsp; Чекбокс:</b>
            <p>
              3.3.1. Чекбокс предоставляет Курьеру доступ в Интерфейс с целью
              получения информации о размещенных в Интерфейсе Заявках на
              доставку, а также для отслеживания информации о состоянии и
              движении своих Заявок, о новых заявках,&nbsp; размере
              вознаграждения Курьера по выполненным Заявкам, иной информации и
              средств ее обработки, используемой сторонами для информацией в
              электронном виде. Доступ в Интерфейс осуществляется после
              регистрации через личный кабинет (телеграм-бот).
            </p>
            <p>
              3.3.2. Чекбокс обязуется оказывать Курьеру консультационную
              поддержку при работе в Интерфейсе по всем&nbsp; вопросам, в том
              числе связанным с регистрацией, получением и обработкой
              Заявок.&nbsp;
            </p>
            <p>
              3.3.3. Чекбокс на основании данных, полученных от Партнера,
              предоставляет Курьеру доступ к информации о Клиентах/Получателях,
              в объеме, необходимом и достаточном для выполнения Курьером
              Заявок.&nbsp;
            </p>
            <p>
              3.3.4. Чекбокс обязуется еженедельно перечислять на расчетный счет
              Курьера вознаграждение за выполненные Заявки, за период с
              понедельника по воскресенье недели, следующей перед расчетной, в
              платежный день, которым признается вторник, на основании
              еженедельных расчетов, в соответствии с данными лицевого счета
              Курьера.
            </p>
            <p>
              3.3.5. В случае нарушения Курьером требований п 3.1.7. Оферты,
              Чекбокс вправе произвести зачет стоимости утраченного Отправления
              и/или и суммы вознаграждения, причитающегося Курьеру за оказание
              услуг в соответствии с настоящей Офертой без дополнительного
              уведомления Курьера.
            </p>
            <p>
              <br />
            </p>
            <h2>4. &nbsp; &nbsp; ПОРЯДОК РАСЧЕТОВ, ВОЗНАГРАЖДЕНИЕ КУРЬЕРА</h2>
            <p>
              4.1. Лицевой счет - способ виртуальных расчетов Сторон через
              Интерфейс, позволяющий Курьеру ежедневно открывать сессии для
              работы через Интерфейс, в том числе для приема новых Заявок,
              расчета стоимости выполненных Заявок,&nbsp; и отслеживания
              задолженности Сторон.&nbsp;
            </p>
            <p>
              4.2. Размер вознаграждения Курьера рассчитывается на основании
              данных лицевого счета в Интерфейсе и определяется как количество
              часов, потраченных Курьером на выполнение Заявок, умноженных на
              тариф, по которому Курьер подключен к Интерфейсу. Вознаграждение
              может меняться по соглашению сторон.
            </p>
            <p>
              4.3. Тариф Курьера может быть изменен путем направления Курьеру в
              электронном виде (по смс, электронной почте либо через Интерфейс)
              соответствующего уведомления, вступающего в силу на следующий
              календарный день с момента его направления. Продолжение выполнения
              обязательств в соответствии с настоящей Офертой признается
              согласием Курьера с изменениями, предусмотренными таким
              уведомлением,&nbsp; и подлежащим применению к расчетам Сторон. В
              случае несогласия с новыми тарифами, Курьер должен немедленно
              уведомить Чекбокс об отказе от продолжения использования
              Интерфейса.
            </p>
            <p>
              4.4. При получении еженедельной выплаты за выполненные заявки от
              Чекбокса, Курьер предоставляет Чекбоксу чек из&nbsp; Приложения
              "Мой налог", данный документ будет являться подтверждением
              расходов Чекбокса. Акты выполненных работ при указанной форме
              расчетов не предусмотрены.
            </p>
            <p>4.5.&nbsp; Все расчеты производятся в рублях РФ.</p>
            <p>
              <br />
            </p>
            <h2>5. &nbsp; &nbsp; ОТВЕТСТВЕННОСТЬ СТОРОН.</h2>
            <p>
              5.1. В случае неисполнения или ненадлежащего исполнения одной из
              сторон своих обязательств, Стороны несут ответственность согласно
              Оферте и разрешаются ими путем переговоров, а в части, не
              урегулированной Офертой – в соответствии с законодательством РФ.
              При недостижении Сторонами соглашения, споры и разногласия
              подлежат разрешению в претензионном порядке. Срок ответа на
              претензию – 30 дней.
            </p>
            <p>5.2.&nbsp; &nbsp; &nbsp; Ответственность Курьера:</p>
            <p>
              5.2.1. Курьер самостоятельно несет ответственность за
              своевременную доставку и вручение Заказов Клиентам/Получателям;
              своевременный возврат Отправлений Партнеру, незамедлительное
              информирование Чекбокс о сбоях, проблемах и других форс-мажорных
              обстоятельствах, препятствующих выполнить свои обязательства перед
              Клиентами&nbsp; в полном объеме.
            </p>
            <p>
              5.2.2.&nbsp; Курьер самостоятельно несет ответственность за
              использование личного кабинета в Интерфейсе, сохранность или
              последствия в случае утери или разглашения данных для входа в
              аккаунт третьими лицами.&nbsp;
            </p>
            <p>
              5.2.3. Курьер несет ответственность перед Партнером в размере
              стоимости Заказа, указанной в Интерфейсе, если Заказ (отдельные
              Товары) по вине Курьера были утрачены или повреждены в период с
              момента получения Курьером Заказов и до момента передачи Заказа
              Клиенту/Получателю.&nbsp;
            </p>
            <p>5.3. Заказ считается утраченным в следующих случаях:</p>
            <p>- в случае хищения Заказа;</p>
            <p>
              - если заказ не получил в Интерфейсе&nbsp; статуса,
              соответствующего действиям с ним, более, чем за 6 часов.
            </p>
            <p>
              5.4. В случае разглашение данных Клиентов, передаваемых в
              соответствии с п.3.1.8. настоящей Оферты Курьеру в целях
              исполнения им обязанностей в соответствии с настоящей Офертой
              (адрес отправления либо получения, телефон), а также жалобы
              Клиента на некорректное поведение Курьера во время доставки,
              Курьер теряет возможность пользоваться Интерфейсом в будущем,
              личный счет и доступ в Интерфейс блокируется без возможности
              восстановления.&nbsp;
            </p>
            <p>5.5. Курьер не несет ответственности:</p>
            <p>
              - в случае если неисполнение или ненадлежащее исполнение Заявки
              произошли по вине/по инициативе Чекбокса, либо Клиента;
            </p>
            <p>
              - за возможное несоответствие Заказа, оформленному Клиентом по
              качеству, количеству, ассортименту и потребительским свойствам, в
              случае, если не были нарушены вес, упаковка, целостность и форма
              Заказа.
            </p>
            <p>5.6.&nbsp; Стороны согласовали, что:&nbsp;</p>
            <p>
              5.6.1. Любые требования к Чекбоксу или в связи с ним
              ограничиваются возмещением реального ущерба и ни при каких
              обстоятельствах не включают упущенную выгоду или иные косвенные
              убытки;&nbsp;
            </p>
            <p>
              5.6.2. Чекбокс не несёт ответственности за неисполнение или
              ненадлежащее исполнение своих обязательств перед Курьером
              (соответственно Курьер - перед Клиентом), если Клиент либо Партнер
              предоставили Чекбоксу либо Курьеру&nbsp; недостоверные, неполные,
              неточные или вводящие в заблуждение сведения или информацию, в том
              числе при заполнении Заявки.
            </p>
            <p>
              5.6.3. Курьер и Чекбокс не несут ответственности за неисполнение
              или ненадлежащее исполнение своих обязательств, если нарушение
              сроков доставки произошли вследствие обстоятельств непреодолимой
              силы или свойств вложения Заказов, о которых Курьеру и Чекбоксу не
              было известно.
            </p>
            <p>
              5.6.4.&nbsp; Чекбокс не несет ответственности перед Курьером за
              невозможность использования Интерфейса по причинам, не зависящим
              от Чекбокса, любые действия или бездействия сервисов, сетей,
              программного обеспечения, оборудования, и т.д., а также за ущерб,
              который может быть нанесен любым устройствам и носителям
              информации Курьера в результате использования
              Интерфейса.&nbsp;&nbsp;
            </p>
            <p>
              5.6.5.&nbsp; Чекбокс не несет ответственности за
              несанкционированное или неправомерное использование третьими
              лицами личного кабинета Курьера в Интерфейсе.
            </p>
            <p>
              5.7. Неустойки и компенсация убытков, предусмотренные настоящим
              Договором, начисляются и выплачиваются только на основании
              письменной претензии пострадавшей Стороны.
            </p>
            <p>
              5.8. Стороны освобождаются от ответственности за частичное или
              полное неисполнение обязательств по настоящей Оферте, если это
              неисполнение явилось следствием обстоятельств непреодолимой силы
              (форс-мажор), возникших после акцепта настоящей Оферты, в
              результате событий чрезвычайного характера, которые Курьер либо
              Чекбокс не могли ни предвидеть, ни предотвратить разумными мерами.
            </p>
            <p>
              <br />
            </p>
            <h2>6. &nbsp; ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ&nbsp;</h2>
            <p>
              6.1. Чекбокс является правообладателем Интерфейса и его составных
              частей.
            </p>
            <p>
              6.2. Контент, доступный в Интерфейсе является объектом
              исключительных прав Чекбокса.
            </p>
            <p>
              6.3. Чекбокс предоставляет Курьеру неисключительную лицензию на
              использование Интерфейса. Неисключительная лицензия ограничена
              «правом на использование», и ни одно из положений Оферты не
              означает передачи Курьеру исключительного права на Интерфейс и/или
              его составные части.
            </p>
            <p>
              6.4. Использование Интерфейса способами, не предусмотренными
              функционалом, в том числе путем копирования контента, элементов
              дизайна строго запрещены, если иное не предусмотрено соглашением
              Сторон.
            </p>
            <p>
              6.5.&nbsp; Чекбокс имеет право без дополнительного согласования
              или уведомления:
            </p>
            <p>6.5.1. Изменять, дорабатывать и обновлять Интерфейс.</p>
            <p>
              6.5.2. Устанавливать любые ограничения на использование&nbsp;
              Интерфейса.
            </p>
            <p>
              6.5.3. Направлять Курьеру сообщения, уведомления, запросы,
              сведения информационного характера.
            </p>
            <p>
              6.5.4. Получать доступ к Личному кабинету Курьера для мониторинга
              работы Интерфейса.
            </p>
            <p>
              6.5.5. Проводить профилактические работы, влекущие приостановление
              работы Сайта и Интерфейса.
            </p>
            <p>
              <br />
            </p>
            <h2>7.&nbsp; &nbsp; ЗАПРЕЩЕННЫЕ ДЕЙСТВИЯ. БЛОКИРОВКА.</h2>
            <p>7.1. &nbsp; Курьер не вправе:</p>
            <p>
              7.1.1. Использовать Интерфейс способами, прямо не предусмотренными
              Офертой.
            </p>
            <p>
              7.1.2. Изменять, настраивать, переводить или создавать производные
              продукты, основанные на Интерфейсе, а также интегрировать
              Интерфейс в другие результаты интеллектуальной деятельности.
            </p>
            <p>
              7.1.3. Предпринимать попытки обойти технические ограничения,
              установленные в Интерфейсе.
            </p>
            <p>
              7.2. Чекбокс вправе незамедлительно и без дополнительного
              оповещения Курьера осуществить блокировку Личного кабинета в
              случае:
            </p>
            <p>
              7.2.1. Выявления нарушения Курьером положений Оферты, в том числе
              п.3.1.8 (разглашения данных Клиентов);
            </p>
            <p>
              7.2.2. Выявления нарушения Курьером положений действующего
              законодательства Российской Федерации;
            </p>
            <p>
              7.2.3. Получения от третьих лиц претензии о нарушении Курьером их
              прав;
            </p>
            <p>
              7.2.4. Получения соответствующих требований от государственных
              органов.
            </p>
            <p>
              7.3. Блокировка означает невозможность для Курьера использовать
              функционал Интерфейса.
            </p>
            <p>
              7.4. Курьер обязуется самостоятельно предпринять все усилия для
              прекращения действий, вызвавших блокировки.
            </p>
            <p>
              7.5. В случае неустранения Курьером причин, вызвавших
              блокировку,Чекбокс вправе удалить аккаунт Курьера.
            </p>
            <p>
              <br />
            </p>
            <h2>8. &nbsp; &nbsp; ПЕРСОНАЛЬНЫЕ ДАННЫЕ</h2>
            <p>
              8.1. При регистрации в Интерфейсе Курьер обязан предоставить
              достоверную и актуальную информацию, предусмотренную
              регистрационной формой, для формирования Личного кабинета.
            </p>
            <p>
              8.2. Принимая настоящую Оферту, Курьер предоставляет Чекбоксу свое
              согласие на обработку своих персональных данных в объеме,
              установленном настоящей Офертой и их использование в целях
              оказания услуг. Данные, передаваемые Чекбоксу: ФИО; Номер
              мобильного телефона Курьера, банковские данные, ИНН.&nbsp;
            </p>
            <p>
              Целью обработки персональных данных является визуальное
              отображение Чекбоксом данных в смс-сообщениях, направляемых
              Клиентам с целью оповещения их о времени и параметрах доставки
              Заказа, совершенного через Интерфейс и возможной связи Клиента с
              Курьером с целью уточнения состояния доставки, а также для
              осуществления выплат за выполнение Курьером Заказов на доставку,
              на основании Оферты.&nbsp;
            </p>
            <p>
              8.3. Обработка персональных данных осуществляется как с
              использованием средств автоматизации, так и без использования
              таких средств.
            </p>
            <p>
              8.4. В рамках Оферты обрабатываются персональные данные, не
              являющиеся биометрическими: сведения о местоположении, тип и
              версия ОС, тип устройства, информация о разделах мобильного
              приложения и Интерфейса, посещаемых Курьером, ip-адрес.
            </p>
            <p>
              8.5. Срок, в течение которого происходит обработка персональных
              данных: до момента отзыва согласия на обработку данных. Курьер
              может направить письменное заявление Чекбоксу по реквизитам,
              указанным в Оферте, с отзывом согласия на обработку данных. В
              случае отзыва Курьером или его представителем согласия на
              обработку персональных данных, Чекбокс удаляет персональные данные
              в течение 30 (тридцати) дней.
            </p>
            <p>
              8.6. Стороны не обрабатывают персональные данные Клиентов, в
              смысле Федерального закона «О персональных данных» от 27.07.20106
              №152-ФЗ и иных нормативных актов Российской Федерации.&nbsp;
            </p>
            <p>
              8.7. При этом Чекбокс не предоставляет Курьеру персональных данных
              Клиентов, в том числе в Интерфейсе, в целях сохранения
              конфиденциальной информации и персональных данных Клиентов.
              Чекбокс подтверждает, что получил согласие на передачу Курьеру
              имени и номера телефона Клиентов, если такие данные используются
              при работе с Интерфейсом.
            </p>
            <p>
              8.8. Полные правила обработки данных урегулированы Политикой
              конфиденциальности Чекбокс.
            </p>
            <p>
              <br />
            </p>
            <h2>9.&nbsp; &nbsp; СРОК ДЕЙСТВИЯ ОФЕРТЫ</h2>
            <p>
              9.1. Оферта действует с момента регистрации до момента удаления
              Личного кабинета в Интерфейсе.
            </p>
            <p>9.2. Аккаунт может быть удален:</p>
            <p>9.2.1. Чекбоксом по письменному требованию Курьера;</p>
            <p>9.2.2. по основаниям, предусмотренным Офертой.</p>
            <p>
              9.3. После удаления мобильного приложения (Интерфейса) Курьер
              утрачивает возможность использовать его функционал.
            </p>
            <p>
              <br />
            </p>
            <h2>10.&nbsp; &nbsp; ЗАВЕРЕНИЯ И ГАРАНТИИ</h2>
            <p>
              10.1. Курьер гарантирует в установленные законодательством
              Российской Федерации сроки исполнять обязанности по исчислению и
              уплате налогов и предоставлению в налоговые инспекции
              соответствующих деклараций в полном объеме.&nbsp;&nbsp;
            </p>
            <p>
              10.2. Курьер обязуется по запросу Чекбокса в течение 5 (пяти)
              рабочих дней предоставлять документы, подтверждающие факт уплаты
              Курьером сумм налогов за налоговые или отчетные периоды, в которых
              у Курьера возникла обязанность по уплате налогов в связи с
              настоящей Офертой, а также, подтверждающие его налоговый статус.
            </p>
            <p>
              10.3. Чекбокс не является налоговым агентом по НДФЛ в смысле
              статьи 226 Налогового кодекса РФ.
            </p>
            <p>
              <br />
            </p>
            <h2>11.&nbsp; ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ.</h2>
            <p>
              11.1. Оферта может быть изменена или дополнена Чекбоксом без
              уведомления Курьера в любое время. Новая редакция Оферты вступает
              в силу с момента ее размещения на Сайте и в Интерфейсе.
            </p>
            <p>
              11.2. Продолжение использования Интерфейса после внесения
              изменений и/или дополнений в ОФерту означает принятие и согласие
              Курьера с такими изменениями и/или дополнениями.
            </p>
            <p>
              11.3. Оферта регулируется и толкуется в соответствии с
              законодательством Российской Федерации. Вопросы, не
              урегулированные Офертой, подлежат разрешению в соответствии с
              законодательством РФ.
            </p>
            <p>
              11.4. В случае, если какое-либо из положений Оферты окажется
              ничтожным в соответствии с законодательством Российской Федерации,
              остальные положения остаются в силе, а Оферта будет исполняться
              Сторонами в полном объеме без учета такого положения.
            </p>
            <p>
              11.5. Ничто в Оферте не может пониматься как установление между
              Курьером и Чекбоксом отношений товарищества, отношений по
              совместной деятельности, отношений личного найма, либо каких-то
              иных отношений, прямо не названных в Оферте.
            </p>
            <p>
              <br />
              <br />
            </p>
            <h2>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;БАНКОВСКИЕ
              РЕКВИЗИТЫ&nbsp;
            </h2>
            <table>
              <tbody>
                <tr>
                  <td>
                    <br />
                  </td>
                  <td>
                    <p>Чекбокс: &nbsp; АО «САМОКАРТ»</p>
                    <p>ИНН 2310212217&nbsp; КПП 231001001</p>
                    <p>
                      г. Краснодар, ул. Стахановская, 15,&nbsp; корп. 3, комната
                      2.
                    </p>
                    <p>р/с 40702810026020005282</p>
                    <p>
                      в филиале «Ростовский»&nbsp; АО АЛЬФА-БАНК, г. Краснодар
                    </p>
                    <p>к/с 30101810500000000207</p>
                    <p>БИК 046015207</p>
                    <p>
                      e-mail:{" "}
                      <a href="mailto:info@samocart.ru">info@samocart.ru</a>
                    </p>
                    <p>Служба поддержки: +7 (918) 060-03-96</p>
                    <br />
                    <p>
                      Генеральный директор:&nbsp; Евдаков Кирилл Сергеевич,{" "}
                      <a href="mailto:k@samocart.ru">k@samocart.ru</a>
                    </p>
                    <br />
                    <br />
                    <br />
                    <p>Редакция Оферты от 26.11.2020 года.&nbsp;</p>
                    <br />
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default PageShippingVoronezh;
